import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePubNub } from 'pubnub-react';

import { toCurrency } from 'app/helpers/currencyHelpers';
import { starRating, callButton } from 'app/helpers/listingHelpers';

const DeprecatedPhoneListingCard = ({ listing, headingSource, style }) => {
  const pubnub = usePubNub();
  const listingUrl = `/listings/show/${listing.slug}`;

  const heading = headingSource === 'category' ? listing.full_category : listing.login;

  const channels = [listing.push_channel];
  // subscribe on load, and unsubscribe on unload
  useEffect(() => {
    pubnub.subscribe({ channels });
    return () => { pubnub.unsubscribe({ channels }); };
  }, []);

  return (
    <div className="listing" style={style}>
      <div className="container-fluid padding-0-8" role="link" tabIndex="0">
        <div className="row">
          <div className="col-xs-4 padding10 center-xs">
            <a href={listingUrl}>
              <div className="pic">
                <img src={listing.photo_url} alt="Listing Thumbnail" />
              </div>
              <div className="stars">
                {starRating(listing.rating)}
                <p>
                  (
                  {listing.rating}
                  )
                </p>
              </div>
            </a>
          </div>
          <div className="col-xs-8 start-xs padding10">
            <div className="container-fluid padding-0-8">
              <div className="row name">
                <a href={listingUrl}>
                  {heading}
                </a>
              </div>
              <div className="row description">
                <a href={listingUrl}>
                  {listing.title}
                </a>
              </div>
              <div className="row details">
                <div className="col-xs-4 marginlftminus8">
                  <a href={listingUrl}>
                    Details
                  </a>
                </div>
                <div className="col-xs-8 end-xs price">
                  {toCurrency(listing.price_per_minute)}
                  &nbsp;
                  <span>per min.</span>
                </div>
              </div>
              <div className="row margintop20">
                {callButton(listing)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeprecatedPhoneListingCard.defaultProps = {
  headingSource: null,
  style: {},
};

DeprecatedPhoneListingCard.propTypes = {
  listing: PropTypes.object.isRequired,
  headingSource: PropTypes.string,
  style: PropTypes.object,
};

export default DeprecatedPhoneListingCard;
