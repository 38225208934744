import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Reorder } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import ListingCardContainer from 'app/redesign/containers/ListingCardContainer';
import MenuKebab from 'app/components/favorite_listings/MenuKebab';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';

const GridItemKebab = ({ isSortable, children }) => {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: isSortable ? 'center' : 'flex-end',
        width: {
          xs: isSortable ? theme.spacing(2.8) : theme.spacing(5.8),
          sm: isSortable ? theme.spacing(3.5) : theme.spacing(7.5),
        },
      }}
    >
      {children}
    </Grid>
  );
};

GridItemKebab.propTypes = {
  isSortable: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const FavoriteCardBox = styled(Box)(() => ({
  '&.MuiBox-root': {
    border: '1px solid #ddd',
    margin: '6px',
    backgroundColor: 'var(--body_background)',
  },
}));

const ListingCardBox = ({ children }) => {
  return (
    <Box
      sx={{
        width: {
          xs: '300px',
          sm: '380px',
        },
      }}
    >
      {children}
    </Box>
  );
};

ListingCardBox.propTypes = {
  children: PropTypes.node.isRequired,
};

const ReorderHandle = () => {
  return (
    <Grid item xs={1.1} sx={{ backgroundColor: '#eee', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Reorder />
    </Grid>
  );
};

const FavoriteListingCard = ({ favoriteListing, checked }) => {
  const { handleListingCheckBox, isSortable } = useContext(FavoriteListingsContext);
  const { listing_id: listingId } = favoriteListing;
  return (
    <FavoriteCardBox>
      <FormControlLabel
        style={{ margin: 'auto' }}
        control={(
          <Checkbox
            checked={checked}
            onChange={() => { handleListingCheckBox(listingId); }}
            sx={{ padding: '5px', display: 'flex', alignSelf: 'flex-start', borderRadius: '0' }}
          />
        )}
        label={(
          <ListingCardBox>
            <Grid container>
              <Grid item xs={10}>
                <Box className="favorites-cards">
                  <ListingCardContainer
                    listingId={listingId}
                    style={{ border: 'none', background: 'none' }}
                  />
                </Box>
              </Grid>
              <GridItemKebab item xs={1} isSortable={isSortable}>
                <MenuKebab favoriteListing={favoriteListing} />
              </GridItemKebab>
              {isSortable && (<ReorderHandle />)}
            </Grid>
          </ListingCardBox>
        )}
      />
    </FavoriteCardBox>
  );
};

FavoriteListingCard.propTypes = {
  favoriteListing: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default FavoriteListingCard;
