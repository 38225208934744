import React from 'react';
import PropTypes from 'prop-types';

import PhoneShape from 'app/shapes/PhoneShape';
import ListingShape from 'app/shapes/ListingShape';

import {
  Grid,
  Stack,
} from '@mui/material';

import CallNowButton from 'app/components/shared/CallNowButton';

import { toNumber } from 'app/helpers/currencyHelpers';

const CallNow = ({
  selectedPhone,
  balance,
  listing,
  isLoading,
}) => {
  const canCall = !isLoading && selectedPhone && toNumber(balance.available_balance) >= listing.price_per_minute;

  return (
    <Grid
      item
      xs={12}
      mt={2}
      mb={1}
    >
      <Stack
        direction="row"
        mt={3}
        justifyContent="center"
        sx={{ textAlign: 'center' }}
      >
        { listing && (
          <CallNowButton
            disabled={!canCall}
            onClick={() => {}}
            listing={listing}
          />
        )}
      </Stack>
    </Grid>
  );
};

CallNow.defaultProps = {
  selectedPhone: null,
  balance: null,
  listing: null,
};

CallNow.propTypes = {
  selectedPhone: PhoneShape,
  balance: PropTypes.object,
  listing: ListingShape,
  isLoading: PropTypes.bool.isRequired,
};

export default CallNow;
