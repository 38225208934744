import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import axios from 'axios';
import 'react-image-crop/src/ReactCrop.scss';
import { canvasPreview } from 'app/helpers/canvasPreview';

import { usePerformCreateProfileImageMutation } from 'app/api/profileImageApi';

const ProfileImageModal = ({
  closeModal,
  closeAllModals,
  mode,
  image,
  advisor,
  selectedAlbum,
  fmToken,
  setDenyCloseModalRequests,
  filesUploaderUrl,
}) => {
  const previewCanvasRef = useRef();
  const imageRef = useRef();
  const imageName = useRef();
  const imageType = useRef('image/jpeg');
  const [imageSource, setImageSource] = useState('');
  const [crop, setCrop] = useState();
  const [error, setError] = useState('');
  const [performingSave, setPerformingSave] = useState(false);
  const [completedCrop, setCompletedCrop] = useState();

  const [performCreateProfileImage, {
    status: createProfileImageStatus,
    error: createProfileImageErrors,
  }] = usePerformCreateProfileImageMutation();

  useEffect(() => {
    if (createProfileImageStatus === 'fulfilled') {
      closeAllModals();
    }
  }, [createProfileImageStatus]);

  useEffect(() => {
    setError(createProfileImageErrors?.data?.errors);
  }, [createProfileImageErrors]);

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    let crop;

    if (mode === 'avatar') {
      crop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 50,
          },
          1,
          width,
          height,
        ),
        width,
        height,
      );
    } else {
      crop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 90,
          },
          16 / 6,
          width,
          height,
        ),
        width,
        height,
      );
    }
    setCrop(crop);
    setCompletedCrop(crop);
  };

  const onSaveClick = () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop preview does not exist');
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob');
      }
      uploadImage(blob);
    }, imageType.current, 1);
  };

  const uploadImage = (blob) => {
    setDenyCloseModalRequests(true);
    setPerformingSave(true);

    const imageParts = imageName.current.split('.');
    const fileExt = imageParts.pop();
    const fileName = imageParts.join('.');
    const name = mode === 'avatar' ? `${fileName}_avatar.${fileExt}` : `${fileName}_cover.${fileExt}`;
    const formData = new FormData();

    formData.append('item[seller_album_id]', selectedAlbum.value);
    formData.append('item[file]', blob, name);
    formData.append('create_or_return_duplicate_file', true);
    formData.append('token', fmToken);
    formData.append('upload_from', 'nf_plus');

    return axios({
      method: 'POST',
      url: filesUploaderUrl,
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => {
      createImageInMain(response.data?.id);
      setDenyCloseModalRequests(false);
      setPerformingSave(false);
    }).catch((error) => {
      setPerformingSave(false);
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError(error.message);
      }
    });
  };

  const createImageInMain = (fmId) => {
    performCreateProfileImage({
      fmId,
      userId: advisor.id,
      type: mode,
    });
  };

  useEffect(() => {
    if (image.hasOwnProperty('url')) {
      setImageSource(image.url);
      const fileName = image.file_name.replace(/\.[^/.]+$/, '');
      imageName.current = `${fileName}.jpg`;
    } else {
      imageName.current = image.name;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSource(reader.result?.toString() || '');
      });
      imageType.current = image.type;
      reader.readAsDataURL(image);
    }
  });

  useEffect(() => {
    const t = setTimeout(() => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imageRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imageRef.current,
          previewCanvasRef.current,
          completedCrop,
        );
      }
    }, 100);

    return () => {
      clearTimeout(t);
    };
  });

  const cropPreview = () => {
    if (!completedCrop) return undefined;

    if (mode === 'avatar') {
      return (
        <canvas
          ref={previewCanvasRef}
          style={previewStyles}
        />
      );
    }

    return (
      <div className="aci-cropPreview-container">
        <canvas
          ref={previewCanvasRef}
          style={previewStyles}
        />
        <div className="aci-cropPreview-overlay">
          <div className="container-fluid aci-items">
            <div className="row">
              <div><img src="/plus_frontend/assets/ico-no-photo.png" alt="" className="aci-no-photo" /></div>
              <div className="container-fluid">
                <div className="row start-xs aci-login">
                  {advisor.login ? advisor.login : 'Flirt'}
                </div>
                <div className="row start-xs">
                  <div className="aci-icon"><img src="/plus_frontend/assets/svg/ico-top-tribute.svg" alt="" /></div>
                  <div className="aci-icon"><img src="/plus_frontend/assets/svg/ico-top-chat.svg" alt="" /></div>
                  <div className="aci-icon"><img src="/plus_frontend/assets/svg/ico-top-call.svg" alt="" /></div>
                  <div className="aci-icon"><img src="/plus_frontend/assets/svg/btn-follow.svg" alt="" /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="aci-opacity">&nbsp;</div>
        </div>
      </div>
    );
  };

  const circularCrop = mode === 'avatar';
  const aspect = mode === 'avatar' ? 1 : (16 / 6);

  const previewStyles = {
    objectFit: 'contain',
    width: mode === 'avatar' ? '150px' : '300px',
    height: mode === 'avatar' ? '150px' : '111px',
    borderRadius: mode === 'avatar' ? '50%' : '0%',
  };

  const imageStyles = {
    maxWidth: mode === 'avatar' ? '270px' : '300px',
    maxHeight: '400px',
  };

  const errorMessage = error && (
    <div className="text-error">
      {error}
    </div>
  );

  return (
    <div test-data-id="preview-image-modal">
      <div className="modal-header">
        <h4>Preview Image</h4>
      </div>
      <div className="modal-body">
        {errorMessage}
        <div className="container-fluid padding-0-8 margintop20">
          <div className="row center-xs marginbot20">
            <div className="col-sm-12 start-sm col-xs-12 start-xs padding0">
              Drag to move the image or crop to adjust the size. Please follow&nbsp;
              <a href="https://support.niteflirt.com/hc/en-us/articles/212830688-NiteFlirt-Rules" target="_blank" rel="noreferrer">NiteFlirt Rules</a>
              .
            </div>
          </div>
          <div className="row center-xs marginbot20">
            <div className="col-sm-6 col-xs-12">
              <ReactCrop
                aspect={aspect}
                crop={crop}
                circularCrop={circularCrop}
                keepSelection
                onChange={c => setCrop(c)}
                onComplete={c => setCompletedCrop(c)}
                style={imageStyles}
              >
                <img ref={imageRef} src={imageSource} onLoad={onImageLoad} crossOrigin="anonymous" />
              </ReactCrop>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="aci-preview-container">
                <div className="aci-title">Preview</div>
                {cropPreview()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aci-spacer" />
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={closeModal}
        >
          Cancel
        </a>
        <button
          type="button"
          className={`btn btn-submit ${performingSave ? 'submitting' : ''}`}
          onClick={onSaveClick}
          disabled={performingSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

ProfileImageModal.defaultProps = {
  advisor: {},
};

ProfileImageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closeAllModals: PropTypes.func.isRequired,
  setDenyCloseModalRequests: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  selectedAlbum: PropTypes.object.isRequired,
  fmToken: PropTypes.string.isRequired,
  filesUploaderUrl: PropTypes.string.isRequired,
  advisor: PropTypes.object,
};

export default ProfileImageModal;
