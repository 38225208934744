import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';

import { grey, green, orange } from '@mui/material/colors';

import CallIconNF from 'app/components/customSvgs/CallIconNF';
import ChatIconNF from 'app/components/customSvgs/ChatIconNF';
import ChatIconExclNF from 'app/components/customSvgs/ChatIconExclNF';
import TrashIconNF from 'app/components/customSvgs/TrashIconNF';
import PhoneShape from 'app/shapes/PhoneShape';
import ValidatePhone from 'app/redesign/components/availability/ValidatePhone';
import ModalsContext from 'app/contexts/ModalsContext';
import GenericModal from 'app/components/modals/GenericModal';

import {
  useUpdateAvailabilityMutation,
  useDeletePhoneMutation,
} from 'app/api/mainApi';

const Phone = ({
  phone,
  lastPhone,
  isSelectedForVerification,
  setSelectedForVerification,
}) => {
  const [updateCurrentCallPhone] = useUpdateAvailabilityMutation();
  const updateCurrentChatPhone = updateCurrentCallPhone;
  const [verifying, setVerifying] = useState(false);

  const [deletePhone] = useDeletePhoneMutation();
  const { openModal } = useContext(ModalsContext);

  const handleChangeCurrentCallPhone = () => {
    if (phone.is_current_call_phone) { return; }
    const phoneType = phone.phone_type;

    updateCurrentCallPhone({ availability: { phone_type: phoneType } });
  };

  const handleDeletePhone = () => {
    return openModal({
      component: GenericModal,
      props: {
        callToAction: () => deletePhone(phone.id),
        ctaButtonLabel: 'Delete',
        header: 'Delete Phone',
        body: 'Delete this phone number?',
      },
    });
  };

  const handleChangeCurrentChatPhone = () => {
    if (phone.is_current_chat_phone) { return; }

    updateCurrentChatPhone({ availability: { phone_id: phone.id }, silent: false });
  };

  const phoneNumber = phone.unsecure_number || phone.secure_number;

  const displayStylesForVerification = isSelectedForVerification ? {} : { display: 'none' };

  return (
    <>
      <ListItem test-data-id="phone">
        <ListItemText primary={`${phone.title}: ${phoneNumber}`} />
        <Stack direction="row" spacing={1}>
          <CallIconNF
            onClick={handleChangeCurrentCallPhone}
            className="ico-list-avail"
            test-data-id={`${phone.is_current_call_phone ? 'call-icon-green' : 'call-icon-grey'}`}
            sx={{ color: phone.is_current_call_phone ? green[500] : grey[600] }}
          />
          { phone.domestic && phone.can_chat && (
            <ChatIconNF
              onClick={handleChangeCurrentChatPhone}
              className="ico-list-avail"
              test-data-id={`${phone.is_current_chat_phone ? 'chat-icon-green' : 'chat-icon-grey'}`}
              sx={{ color: phone.is_current_chat_phone ? green[500] : grey[600] }}
            />
          )}
          { phone.domestic && !phone.can_chat && (
            <ChatIconExclNF
              className="ico-list-avail"
              test-data-id="chat-icon-orange"
              sx={{ color: orange[600] }}
              onClick={() => {
                setVerifying(true);
                setSelectedForVerification(phone.id);
              }}
            />
          )}
          { !lastPhone && (
            <TrashIconNF
              onClick={handleDeletePhone}
              className="ico-list-avail"
              test-data-id="trash-icon"
              sx={{ color: grey[600] }}
            />
          )}
        </Stack>
      </ListItem>
      { verifying && (
        <ListItem
          direction="row"
          spacing={1}
          sx={displayStylesForVerification}
        >
          <ValidatePhone
            phoneId={phone.id}
            setVerifying={setVerifying}
            setSelectedForVerification={setSelectedForVerification}
          />
        </ListItem>
      )}
    </>
  );
};

Phone.defaultProps = {
  phone: {},
  lastPhone: false,
};

Phone.propTypes = {
  phone: PhoneShape,
  lastPhone: PropTypes.bool,
  isSelectedForVerification: PropTypes.bool.isRequired,
  setSelectedForVerification: PropTypes.func.isRequired,
};

export default Phone;
