import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { featureFlag } from 'app/util/featureFlag';
import {
  useGetPublicPackagesForAdvisorQuery,
  useLazyGetGoodyBagsPresenceQuery,
} from 'app/api/mainApi';

import TabContent from 'app/redesign/components/TabContent';
import ListingsContainer from 'app/redesign/containers/ListingsContainer';
import GoodyBagsContainer from 'app/redesign/containers/GoodyBagsContainer';
import PackagesContainer from 'app/containers/PackagesContainer';
import StorefrontFeedContainer from 'app/containers/StorefrontFeedContainer';
import ProfileContext from 'app/contexts/ProfileContext';

const ProfileTabsPos = styled(Box)(() => ({
  '&.MuiBox-root': {
    marginTop: '18px',
    borderTop: '1px solid #e0e0e0',
    '& [role="tabpanel"] .MuiBox-root': {
      '@media all and (max-width: 600px) {': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  },
}));

const CustomTabs = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    margin: '0 12px 0 -12px',
    display: 'flex',
    '& .MuiTab-root': {
      flexGrow: '1',
      minWidth: 'auto',
    },
    '& .MuiButtonBase-root': {
      padding: '0px',
    },
  },
}));

const MarginBox = styled(Box)(() => ({
  '&.MuiBox-root': {
    margin: '-24px -24px 0 -24px',
  },
}));

const ProfileTabsWithContent = ({
  stickyNavVisible,
  advisor,
  advisorLoading,
  viewingSelf,
  currentUser,
}) => {
  const { tab } = useContext(ProfileContext) || {};
  const navigate = useNavigate();

  let initialTab;
  switch (tab) {
  case 'gb':
  case 'goodies':
    initialTab = 1;
    break;
  case 'feed':
    initialTab = 2;
    break;
  case 'packages':
    initialTab = 3;
    break;
  default:
    initialTab = 0;
  }
  const [selectedTab, setSelectedTab] = React.useState(initialTab);
  const { path } = useParams();
  const { data: packages, isLoading: packagesLoading } = useGetPublicPackagesForAdvisorQuery({ advisorId: advisor?.id });
  const [triggerGetGoodyBagsPresenceQuery, goodyBagsPresenceResult] = useLazyGetGoodyBagsPresenceQuery();
  const goodyBagsPresent = goodyBagsPresenceResult?.data?.goody_bags_present;

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);

    // this will change the URL so that a refresh or a bookmark won't lose the current tab
    const basePath = `/${advisor?.login}`;
    switch (newValue) {
    case 0:
      navigate(basePath);
      break;
    case 1:
      navigate(`${basePath}/goodies`);
      break;
    case 2:
      navigate(`${basePath}/feed`);
      break;
    case 3:
      navigate(`${basePath}/packages`);
      break;
    default:
      navigate(basePath);
    }
  };

  const navContainerStickyCSS = {
    position: 'sticky',
    top: '43px',
    padding: '0px',
    zIndex: '9',
    backgroundColor: 'white',
  };

  const handleStickyNavCSS = () => {
    const baseCSS = { borderBottom: 1, borderColor: 'divider' };

    if (stickyNavVisible) {
      return { ...navContainerStickyCSS, ...baseCSS };
    } else {
      return baseCSS;
    }
  };

  useEffect(() => {
    if (advisor?.login) {
      triggerGetGoodyBagsPresenceQuery({ login: advisor.login });
    }
  }, [advisor]);

  return (
    <ProfileTabsPos>
      <Box sx={handleStickyNavCSS()}>
        <CustomTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="Profile Tabs"
        >
          <Tab label="Phone Sex" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
          <Tab
            label="Goodies"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
            disabled={!goodyBagsPresent}
            sx={goodyBagsPresent ? {} : { display: 'none' }}
          />
          {featureFlag.enabled('FEED_TO_STOREFRONT_20571') && advisor?.onboarding_complete && (
            <Tab label="Feed" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
          )}
          {featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && !packagesLoading && packages?.length && (
            <Tab label="Packages" id="simple-tab-3" aria-controls="simple-tabpanel-3" />
          )}
        </CustomTabs>
      </Box>

      <TabContent value={selectedTab} index={0}>
        <ListingsContainer advisor={advisor} />
      </TabContent>

      <TabContent value={selectedTab} index={1}>
        <GoodyBagsContainer
          advisor={advisor}
          advisorLoading={advisorLoading}
          selectedGoodyBag={path}
          viewingSelf={viewingSelf}
          currentUser={currentUser}
        />
      </TabContent>

      <TabContent value={selectedTab} index={2}>
        <MarginBox>
          <StorefrontFeedContainer advisor={advisor} path={path} />
        </MarginBox>
      </TabContent>

      <TabContent value={selectedTab} index={3}>
        <PackagesContainer
          advisor={advisor}
          selectedPackage={path}
        />
      </TabContent>
    </ProfileTabsPos>
  );
};

ProfileTabsWithContent.defaultProps = {
  stickyNavVisible: false,
  advisor: {},
  advisorLoading: true,
  currentUser: {},
};

ProfileTabsWithContent.propTypes = {
  stickyNavVisible: PropTypes.bool,
  advisor: PropTypes.object,
  advisorLoading: PropTypes.bool,
  viewingSelf: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default ProfileTabsWithContent;
