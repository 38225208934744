import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { FormButton, FormButtonBox, FormTextField, FormWrapper } from 'app/components/form_components';
import useServerErrors from 'app/hooks/form/useServerErrors';
import { useGetUserQuery, useUpdateUserMutation } from 'app/api/mainApi';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PasswordMeter from 'app/components/account_info/PasswordMeter';
import { PasswordHelpText } from 'app/components/account_info/AccountInfoTexts';

const validatePin = (pin) => {
  if (!/^[0-9]*$/.test(pin)) {
    return 'PIN must be numeric.';
  }
  if (pin.length !== 4) {
    return 'Please enter 4 digits.';
  }

  const p = pin.split('').map(ch => parseInt(ch, 10));

  const isSequential = (arr) => {
    const isIncreasing = arr.every((val, index, array) => !index || val === array[index - 1] + 1);
    const isDecreasing = arr.every((val, index, array) => !index || val === array[index - 1] - 1);
    return isIncreasing || isDecreasing;
  };

  if (isSequential(p)) {
    return 'Your PIN cannot be sequential.';
  }

  if (p.every(val => val === p[0])) {
    return 'Your PIN cannot have the same 4 digits.';
  }

  return true;
};

// Default values for the form
const defaultValues = {
  old_password: '',
  password: '',
  password_confirmation: '',
  pin: '',
};

// Transform server response to form values
const getFieldValues = (record) => ({
  pin: record.pin,
});

const PasswordSecurityContainer = () => {
  // form setup
  const recordKey = 'user';
  const query = useGetUserQuery();
  const record = query.data && query.data[recordKey];
  const [mutationUpdate, mutationUpdateStatus] = useUpdateUserMutation();
  const mutation = { update: mutationUpdate, updateStatus: mutationUpdateStatus };
  const form = useForm({
    mode: 'onBlur', // validate fields on blur
    defaultValues,
  });
  useServerErrors(mutation.updateStatus, recordKey, form.setError); // handle server errors

  // Use effect to update form values when server data is available
  useEffect(() => {
    if (record) {
      const initialFieldValues = getFieldValues(record);
      form.reset({
        ...defaultValues,
        ...initialFieldValues,
      });
    }
  }, [record, form]);

  const handleBlur = (_event) => {
    // trigger all field validations
    form.trigger();
  };
  const onSubmit = (formData) => {
    mutation.update(formData);
  };
  useEffect(() => {
    if (mutation.updateStatus.isSuccess) {
      window.location.href = '/account';
    }
  }, [mutation.updateStatus]);
  // end of form setup

  const validateCurrentPassword = (value) => {
    const { password } = form.getValues();
    if (password && !value) {
      return 'Incorrect Password';
    }
    return true;
  };

  const [showPassword, setShowPassword] = useState(false);
  const showPasswordToggle = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)} size="small">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const password = form.watch('password');
  const passwordConfirmation = form.watch('password_confirmation');
  const passwordState = form.getFieldState('password');

  const validatePasswordConfirmation = () => {
    return password !== passwordConfirmation ? 'Passwords do not match' : null;
  };

  useEffect(() => {
    const validationMessage = validatePasswordConfirmation();

    if (passwordState.isTouched) {
      form.setError('password_confirmation', { type: 'manual', message: validationMessage });
    } else {
      form.clearErrors('password_confirmation');
    }
  }, [password, passwordConfirmation, passwordState.isTouched]);

  const hasErrors = Object.keys(form.formState.errors).length > 0;

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  // part of standard form, maybe can be included in the formWrapper
  if (query.isLoading || query.isError) {
    return null;
  }

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '550px' }}>
      <FormWrapper form={form}>
        <Stack spacing={3} mt={2}>
          <FormTextField
            label="Current Password"
            name="old_password"
            type="password"
            variant="outlined"
            size="small"
            fullWidth
            autoComplete="current-password"
            validation={{ validate: validateCurrentPassword }}
          />
          <Stack
            spacing={1}
            mb={3}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
          >
            <FormTextField
              label="Enter new password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              helperText={null} // don't reserve space for error message
              InputProps={showPasswordToggle}
              // we are calling the validation just on the password, but we might consider
              // checking all fields on all blur in the future as a feature of the formWrapper
              onBlur={handleBlur}
            >
              <PasswordMeter password={form.watch('password')} />
            </FormTextField>
            {isPasswordFocused && <PasswordHelpText />}
            <FormTextField
              label="Retype Password"
              name="password_confirmation"
              type={showPassword ? 'text' : 'password'}
              InputProps={showPasswordToggle}
              validation={{ validate: validatePasswordConfirmation }}
            />
          </Stack>

          <Typography variant="body2">
            This Personal Identification Number (PIN) lets you use 1-800-TO-FLIRT connect directly to any Flirt.
          </Typography>
          <FormTextField
            label="PIN"
            name="pin"
            type="tel"
            variant="outlined"
            size="small"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
            validation={{ validate: validatePin }}
            sx={{ maxWidth: 150 }}
          />
        </Stack>
        <FormButtonBox>
          <FormButton
            text="Save"
            variant="contained"
            // rhf only calls onSubmit if form is valid
            onClick={form.handleSubmit(onSubmit)}
            disabled={hasErrors || !form.formState.isValid}
            order={{ xs: 1, sm: 2 }}
          />
          <FormButton
            text="Cancel"
            variant="outlined"
            onClick={() => {
              window.location.href = '/account';
            }}
            order={{ xs: 2, sm: 1 }}
          />
        </FormButtonBox>
      </FormWrapper>
    </Box>
  );
};

export default PasswordSecurityContainer;
