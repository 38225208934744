import React from 'react';
import { PropTypes } from 'prop-types';

import SubscriptionButtonContainer from 'app/containers/SubscriptionButtonContainer';

const counterSection = (imageCount, videoCount, audioCount) => {
  const hasImages = imageCount > 0;
  const hasVideos = videoCount > 0;
  const hasAudios = audioCount > 0;

  return (
    <div className="row center-xs">
      <div className="slick-counter">
        {hasImages && (
          <div test-data-id="image-counter">
            <img src="/plus_frontend/assets/svg/ico-image.svg" alt="Images" />
            <span className="current">
              {imageCount}
            </span>
          </div>
        )}
        {hasVideos && (
          <div test-data-id="video-counter">
            {hasImages && <span className="padding10" />}
            <>
              <img src="/plus_frontend/assets/svg/ico-video.svg" alt="Video" />
              <span className="current">
                {videoCount}
              </span>
            </>
          </div>
        )}
        {hasAudios && (
          <div test-data-id="audio-counter">
            {(hasImages || hasVideos) && <span className="padding10" />}
            <>
              <img src="/plus_frontend/assets/svg/ico-audio.svg" alt="Audio Files" />
              <span className="current">
                {audioCount}
              </span>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

const HiddenSubscriptionPost = ({ advisorLogin, post }) => {
  return (
    <div className="slick-container">
      <div className="container-subscribe">
        <div className="row">
          <div className="col-xs-12">
            <a href="#">
              <img
                src="/plus_frontend/assets/svg/ico-lock.svg"
                alt="Subscribe to Unlock"
                className="ico-lock-big"
              />
            </a>
            <br />
            <SubscriptionButtonContainer
              fetchStateFromApi
              advisorLogin={advisorLogin}
              displayOnHiddenPost
            />
          </div>
        </div>
      </div>
      {
        ((post.image_count > 0) || (post.video_count > 0) || (post.audio_count > 0)) &&
        counterSection(post.image_count, post.video_count, post.audio_count)
      }
    </div>
  );
};

HiddenSubscriptionPost.defaultProps = {
  advisorLogin: null,
};

HiddenSubscriptionPost.propTypes = {
  advisorLogin: PropTypes.string,
  post: PropTypes.object.isRequired,
};

export default HiddenSubscriptionPost;
