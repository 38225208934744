import React from 'react';
import { PropTypes } from 'prop-types';

const LegalLiabilityModal = ({ agreeToLegalLiability, isLoading, closeModal }) => {
  return (
    <div test-data-id="legal-liability-modal" className="nfp-modal">
      <div className="modal-content">
        <div className="modal-close-container">
          <a href="#" onClick={closeModal}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        <div className="modal-header">
          <h4>I certify that:</h4>
        </div>
        <div className="modal-body" style={{ fontSize: '0.875em' }}>
          <p>I certify that I  am legally entitled to upload, publish, and, when intended for sale, to sell all content that has been uploaded to my NiteFlirt account and that I will only upload content where I have such rights.</p>
          <p>I further certify that:</p>
          <ol className="marginlft0 paddinglft20">
            <li className="marginbot15">I have all legally required intellectual property rights to all content uploaded to my NiteFlirt account.</li>
            <li className="marginbot15">All models were 18 years or older on the date the content was created and that I keep records if and as required by  Sections 2257/2257A of Title 18 of the US Code. These records include a copy of a valid government issued identification of all models and will be provided to NiteFlirt upon request.</li>
            <li className="marginbot15">All models depicted in the content have given written consent to be in the content, for the content to be distributed and for the content to be available for download by NiteFlirt users.</li>
            <li className="marginbot15">That appearing in such content was done voluntarily and consent was not done under duress, force or otherwise.</li>
          </ol>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn secondary" onClick={closeModal}>Cancel</a>
          <button type="button" className="btn btn-submit" disabled={isLoading} onClick={agreeToLegalLiability}>I Agree</button>
        </div>
      </div>
    </div>
  );
};

LegalLiabilityModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  agreeToLegalLiability: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LegalLiabilityModal;
