import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

export const FormButtonBox = ({ children }) => {
  return (
    <Box sx={{ marginLeft: -2 }}>
      <Grid container spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end">
        {children}
      </Grid>
    </Box>
  );
};

FormButtonBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormButtonBox;
