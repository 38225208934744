import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DialogsContext from 'app/contexts/DialogsContext';
import SecondaryAuthDialogContainer from 'app/containers/modals/SecondaryAuthDialogContainer';

// Only to be used in plus_frontend, not in the regular frontend code, since it relies
// on being inside a Router context for slicker navigation.
const BehindSecondaryAuth = ({ children }) => {
  const { openDialog } = useContext(DialogsContext);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    openDialog({
      component: SecondaryAuthDialogContainer,
      props: {
        destination: '',
        navMethod: (_to) => setValidated(true),
        hideCancel: true, // this dialog should not be closeable
      },
    });
  }, []);

  return validated ? children : null;
};

BehindSecondaryAuth.propTypes = {
  children: PropTypes.object.isRequired,
};

export default BehindSecondaryAuth;
