import React from 'react';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { green } from '@mui/material/colors';
import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import PhoneShape from 'app/shapes/PhoneShape';
import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';

const SeekerPhone = ({ phone }) => {
  const [updateCurrentCallPhone] = useUpdateAvailabilityMutation();

  const onClick = () => {
    if (phone.is_current_call_phone) { return; }
    const phoneType = phone.phone_type;

    updateCurrentCallPhone({ availability: { phone_type: phoneType } });
  };

  return (
    <ListItemButton test-data-id="seeker-phone" onClick={onClick} mb={0}>
      <ListItemText
        sx={{ width: '100%' }}
        primary={`${phone.title}: ${phone.secure_number}`}
      />
      <ListItemIcon sx={{ display: 'flex !important', justifyContent: 'flex-end' }}>
        { phone.is_current_call_phone && <CheckMarkNF sx={{ color: green[500] }} /> }
      </ListItemIcon>
    </ListItemButton>
  );
};

SeekerPhone.defaultProps = {
  phone: {},
};

SeekerPhone.propTypes = {
  phone: PhoneShape,
};

export default SeekerPhone;
