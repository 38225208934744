import React, { useState } from 'react';

import NotPreviewableMedia from 'app/components/media/NotPreviewableMedia';
import VideoMedia from 'app/components/media/VideoMedia';
import AudioMedia from 'app/components/media/AudioMedia';

const useFlirtMedia = ({ mediaItems }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);

  const currentItem = mediaItems[mediaIndex];

  const nextItem = mediaItems.length > 1 ?
    mediaItems[(mediaIndex + 1) % mediaItems.length] :
    undefined;

  const prevItem = mediaItems.length > 1 ?
    mediaItems[(mediaIndex + mediaItems.length - 1) % mediaItems.length] :
    undefined;

  const pauseAllVideoPlayers = () => {
    const videoPlayers = document.getElementsByClassName('rh5v-DefaultPlayer_video');
    Array.from(videoPlayers).map(p => p.pause());
  };

  const pauseAllAudioPlayers = () => {
    const audioPlayers = document.getElementsByClassName('react-audio-player');
    Array.from(audioPlayers).map(p => p.pause());
  };

  const onNextClick = () => {
    setMediaIndex((mediaIndex + 1) % mediaItems.length);
    pauseAllVideoPlayers();
    pauseAllAudioPlayers();
  };

  const onPrevClick = () => {
    setMediaIndex((mediaIndex + mediaItems.length - 1) % mediaItems.length);
    pauseAllVideoPlayers();
    pauseAllAudioPlayers();
  };

  const onCloseClick = () => {
    setLightboxIsOpen(false);
  };

  const lightboxMediaDecorator = (item, type = undefined) => {
    if (item?.type === 'video' && !item?.previewsPreviewable) {
      return <NotPreviewableMedia />;
    }

    switch (type || item?.type) {
    case 'video':
      return <VideoMedia item={item} />;
    case 'audio':
      return <AudioMedia item={item} />;
    case 'thumb':
      return item?.thumb;
    default:
      return item?.src;
    }
  };

  return {
    nextItem,
    prevItem,
    currentItem,
    onNextClick,
    onPrevClick,
    onCloseClick,
    setMediaIndex,
    lightboxIsOpen,
    setLightboxIsOpen,
    pauseAllVideoPlayers,
    pauseAllAudioPlayers,
    lightboxMediaDecorator,
  };
};

export default useFlirtMedia;
