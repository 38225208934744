import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, Button, DialogContent } from '@mui/material';
import AutoFillableTextField from 'app/components/shared/AutoFillableTextField';
import { usePerformLoginMutation, usePerformPhoneLoginMutation } from 'app/api/mainApi';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { MuiTelInput } from 'mui-tel-input';
import { Link } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const WebForm = ({ login, password, setLogin, setPassword, handleLogin }) => (
  <>
    <Stack direction="column" spacing={3}>
      <AutoFillableTextField
        label="Email Address or Member Name"
        name="login"
        value={login}
        setValue={setLogin}
        onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}
      />

      <AutoFillableTextField
        name="password"
        label="Password"
        type="password"
        value={password}
        setValue={setPassword}
        onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}
      />
    </Stack>
    <Stack mt={1} mb={2} px={1.5}>
      <Typography variant="body2">
        <a href="#">Forgot Password?</a>
      </Typography>
    </Stack>
    <Stack direction="row" justifyContent="center" mb={3}>
      <Button
        variant="contained"
        size="large"
        fullWidth
        onClick={handleLogin}
      >
        Sign In
      </Button>
    </Stack>
  </>
);

// eslint-disable-next-line react/prop-types
const PhoneForm = ({ login, password, setLogin, setPassword, handleLogin }) => (
  <>
    <Stack direction="column" spacing={3}>
      <MuiTelInput
        id="digits"
        label="Phone Number"
        defaultCountry="US"
        forceCallingCode
        disableDropdown
        focusOnSelectCountry
        preferredCountries={['US', 'CA']}
        onChange={setLogin}
        error={false}
        value={login}
        size="small"
      />
      <AutoFillableTextField
        name="pin"
        label="PIN"
        type="password"
        value={password}
        setValue={setPassword}
        onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}
        inputProps={{ maxLength: 4 }}
      />
    </Stack>
    <Stack mt={1} mb={2} px={1.5}>
      <Typography variant="body2">
        <Link to={'/forgot_pin'}>Forgot PIN?</Link>
      </Typography>
    </Stack>
    <Stack direction="row" justifyContent="center" mb={3}>
      <Button
        variant="contained"
        size="large"
        fullWidth
        onClick={handleLogin}
      >
        Sign In
      </Button>
    </Stack>
  </>
);

// This currently relies on the `type` prop to determine which form to render which depends
// on a full page reload to re-render. It will need a refactor to switch between the two forms
// without a full page reload. See the links: Registered on the web? and Registered on the phone?
// I propose to get rid of the type and read the window.location.pathname to determine which form to render.
// Then in the app just render the component in the two routes /login and /login_by_phone without the type prop.
const SignInDialog = ({ type }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  // these params will be passed along unchanged with any form submission
  const queryParameters = new URLSearchParams(window.location.search);
  const additionalParams = ['return_to', 'return_url', 'extra'].reduce((acc, key) => {
    if (queryParameters.get(key)) {
      acc[key] = queryParameters.get(key);
    }
    return acc;
  }, {});

  const mutation = (type === 'phone' && usePerformPhoneLoginMutation) ||
                   (type === 'web' && usePerformLoginMutation);
  const [performLogin, { data: loginData, error: loginError }] = mutation();
  const handleLogin = () => { performLogin({ login, password, additionalParams }); };

  const FormRenderer = type === 'phone' ? PhoneForm : WebForm;

  // if we have a loginData object, we should redirect to the URL it contains
  useEffect(() => {
    if (loginData) window.location.href = loginData.redirectTo;
  }, [loginData]);

  // at some point this will become a function that is passed in,
  //  and swaps out the SignIn modal for the Register modal
  const goToRegister = () => {
    window.location.href = 'signup?return_url=&commit=Register+>';
  };

  return (
    <DialogContent>
      <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
        <a href="/">
          <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
        </a>
        <Typography variant="h3">
          Welcome Back
        </Typography>
      </Stack>
      {loginError && (
        <Stack mb={3}>
          <Typography variant="body3" style={{ color: '#d32f2f' }}>
            <SanitizedHTML html={loginError.data.error} />
          </Typography>
        </Stack>
      )}
      <FormRenderer
        login={login}
        password={password}
        setLogin={setLogin}
        setPassword={setPassword}
        handleLogin={handleLogin}
      />
      <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
        <Typography variant="h5">
          New to NiteFlirt?
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" mb={3}>
        <Button onClick={goToRegister} variant="outlined" size="medium" fullWidth>
          Register
        </Button>
      </Stack>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="body2">
          { type === 'phone' ? (
            <a href="/login">Registered on the web?</a>
          ) : (
            <a href="/login_by_phone">Registered on the phone?</a>
          ) }
        </Typography>
      </Stack>
    </DialogContent>
  );
};

SignInDialog.defaultProps = {
  type: 'web',
};

SignInDialog.propTypes = {
  type: PropTypes.string,
};

export default SignInDialog;
