import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogContent, Link, Stack, Typography, Box } from '@mui/material';
import { FormCheckbox, FormWrapper } from 'app/components/form_components';
import LoginFieldContainer from 'app/containers/account_info/LoginFieldContainer';
import DateOfBirth from 'app/components/account_info/DateOfBirth';
import EmailField from 'app/components/account_info/EmailField';
import PasswordFields from 'app/components/account_info/PasswordFields';
import { useGetNewUserQuery, useCreateUserMutation, useGetAppConfigQuery } from 'app/api/mainApi';
import useServerErrors from 'app/hooks/form/useServerErrors';

const defaultValues = {
  // Set a default empty value
  login: '',
  email: '',
  password: '',
  memberagmt: false,
  enable_platphorm_offers: false,
  login_or_email_pending: false,
};

const getFieldValues = (record) => ({
  login: record.login,
  email: record.email,
  login_or_email_pending: record.login_or_email_pending,
  enable_platphorm_offers: record.enable_platphorm_offers,
});

const TOSLabel = (
  <>
    I agree to the
    {' '}
    <Link href="/help/tos" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </Link>
  </>
);

const SignupCTA = ({ phoneRegistered }) => {
  return (
    phoneRegistered ? (
      <>
        <Typography variant="h3">
          Complete Registration
        </Typography>
        <Typography variant="body1">
          You have already registered on 1-800-TO-FLIRT.
          Please complete your registration so you can take advantage of everything NiteFlirt has to offer.
        </Typography>
      </>
    ) : (
      <Typography variant="h3">
        Register to Get Started
      </Typography>
    )
  );
};

const SignupContainer = () => {
  const appConfig = useGetAppConfigQuery().data;
  const { current_user: currentUser } = appConfig || {};
  const recordKey = 'user';
  const query = useGetNewUserQuery();
  const record = query.data && query.data[recordKey];
  const [update, updateStatus] = useCreateUserMutation();

  // set up the form and provide some functions
  const form = useForm({
    mode: 'onBlur', // validate fields on blur
    defaultValues,
  });

  // handle server errors
  useServerErrors(updateStatus, recordKey, form.setError);

  useEffect(() => {
    if (currentUser && currentUser.id && !query.isLoading && !record.login_or_email_pending) {
      window.location.href = '/account';
    }
  }, [currentUser, record, query]);

  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (record) {
      const initialFieldValues = getFieldValues(record);
      setInitialValues(initialFieldValues);
      form.reset(initialFieldValues);
    }
  }, [record, form.reset]);

  const handleSignIn = () => {
    window.location.href = '/login';
  };

  const onSubmit = (formData) => {
    update(formData);
  };
  useEffect(() => {
    if (updateStatus.isSuccess) {
      window.location.href = '/';
    }
  }, [updateStatus]);

  return (
    <DialogContent>
      <Box mx={{ xs: 0, sm: 12, md: 17 }}>
        <FormWrapper form={form}>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
            <SignupCTA phoneRegistered={initialValues.login_or_email_pending} />
          </Stack>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <LoginFieldContainer initialValue={initialValues.login} required />
            <EmailField />
            <PasswordFields />
          </Stack>
          <Stack direction="column" spacing={1}>
            <DateOfBirth />
            <FormCheckbox
              id="user-accept-tos"
              name="memberagmt"
              label={TOSLabel}
              validation={{ required: 'To continue check the box to accept our Terms of Service.' }}
            />
            <FormCheckbox
              id="user-enable-platphorm-offers"
              name="enable_platphorm_offers"
              label="Yes! Send me NiteFlirt offers and news."
            />
          </Stack>
          <Stack direction="row" justifyContent="center" mb={3}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={form.handleSubmit(onSubmit)}
              disabled={updateStatus.isLoading}
            >
              Register
            </Button>
          </Stack>
        </FormWrapper>
        <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant="h5">
            Already have an account?
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button
            onClick={handleSignIn}
            variant="outlined"
            size="medium"
            fullWidth
          >
            Sign In
          </Button>
        </Stack>
      </Box>
    </DialogContent>
  );
};

export default SignupContainer;
