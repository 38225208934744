import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import GoodyBagShape from 'app/shapes/GoodyBagShape';

const GoodyBagRatingSummary = ({ goodyBag, openRatingsDrawer }) => {
  // TODO: Can we move these classes into an override? or Component
  return (
    <Fragment>
      <Grid item xs={12} sx={{ p: 2, pt: 0.5 }} className="rating">
        { goodyBag.total_ratings > 0 ? (
          <Fragment>
            {goodyBag.approval}
            % Approval
            <span className="padding5" />
            <a
              href="#"
              onClick={openRatingsDrawer}
            >
              {`${goodyBag.total_ratings} ${goodyBag.total_ratings > 1 ? 'ratings' : 'rating'}`}
            </a>
          </Fragment>
        ) : (
          'Not Yet Rated'
        )}
      </Grid>
      <Grid item xs={12} sx={{ p: 0 }}>
        <div className="container-fluid container-thumbs">
          <div className="row middle-xs">
            <div><img src="/plus_frontend/assets/svg/ico-thumb-up.svg" alt="" /></div>
            <div>{goodyBag.up_ratings || 0}</div>
            <div className="padding10" />
            <div><img src="/plus_frontend/assets/svg/ico-thumb-down.svg" alt="" /></div>
            <div>{parseInt((goodyBag.total_ratings - goodyBag.up_ratings)) || 0}</div>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};

GoodyBagRatingSummary.defaultProps = {
  goodyBag: {},
};

GoodyBagRatingSummary.propTypes = {
  goodyBag: GoodyBagShape,
  openRatingsDrawer: PropTypes.func.isRequired,
};

export default GoodyBagRatingSummary;
