import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import ListingShape from 'app/shapes/ListingShape';
import { toCurrency, toNumber } from 'app/helpers/currencyHelpers';

const StyledCard = styled(Card)({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr', // Two columns
  gap: '10px', // Gap between columns
});

const StyledDescription = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 2, // Clamp at 2 lines
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)({
  position: 'relative',
  top: '10px',
  left: '10px',
  marginRight: '20px', // Adding margin to the right
  borderRadius: '0px', // Adding border radius
  width: '80px', // Setting width
  height: '80px', // Setting height
});

const Wrapper = styled('div')({
  display: 'grid',
  gap: '5px', // Gap between rows
  alignItems: 'center', // Align items vertically
});

const Listing = ({
  listing,
  isLoading,
}) => {
  const {
    login,
    title,
    photo_url: avatarSrc,
  } = listing;

  const pricePerMinute = listing.price_per_minute;

  return (
    <StyledCard elevation={0}>
      <Box>
        <StyledAvatar
          alt={login}
          src={avatarSrc}
        />
      </Box>
      <Box>
        <Wrapper>
          <Typography
            variant="h6"
            sx={{ color: '#000' }}
          >
            { isLoading ? '-' : login}
          </Typography>
          <StyledDescription
            variant="body2"
            sx={{ color: '#444' }}
          >
            { isLoading ? '-' : title}
          </StyledDescription>
          <Typography
            variant="body3"
            sx={{ color: '#444' }}
          >
            { isLoading ? '-' : (
              <>
                {toCurrency(toNumber(pricePerMinute))}
                <span style={{ color: '#999' }}>
              &nbsp;per min
                </span>
              </>
            )}
          </Typography>
        </Wrapper>
      </Box>
    </StyledCard>
  );
};

Listing.defaultProps = {
  listing: {},
};

Listing.propTypes = {
  listing: ListingShape,
  isLoading: PropTypes.bool.isRequired,
};

export default Listing;
