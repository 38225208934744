import React from 'react';
import { PropTypes } from 'prop-types';
import SharedCenterNav from './SharedCenterNav';

const UnauthorizedExpandedNav = ({ toggleExpandedNav, isExpanded, closeAndNavigateTo, addMoneyUrl }) => {
  const display = isExpanded ? 'block' : 'none';

  return (
    <>
      <div
        className="more-menu-bg"
        role="link"
        tabIndex={0}
        onClick={toggleExpandedNav}
        style={{ display }}
      >
        &nbsp;
      </div>
      <div className="more-menu" test-data-id="more-menu" style={{ display }}>
        <div className="row end-xs margin10 sticky">
          <a className="menu-close" role="link" tabIndex={0} onClick={toggleExpandedNav}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        <ul className="nav-top paddingtop0">
          <li className="marginbot40 marginleftminus8">
            <div className="menu-initials menu-avatar" style={{ backgroundImage: 'url(/plus_frontend/assets/ico-no-photo.png)' }} />
          </li>
          <SharedCenterNav
            closeAndNavigateTo={closeAndNavigateTo}
            currentUser={null}
            addMoneyUrl={addMoneyUrl}
          />
        </ul>
      </div>
    </>
  );
};

UnauthorizedExpandedNav.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleExpandedNav: PropTypes.func.isRequired,
  closeAndNavigateTo: PropTypes.func.isRequired,
  addMoneyUrl: PropTypes.string.isRequired,
};

export default UnauthorizedExpandedNav;
