import React from 'react';
import PropTypes from 'prop-types';

import { Paper, ListItem, Stack, Typography } from '@mui/material';
import AvailabilityList from 'app/redesign/components/availability/AvailabilityList';

const EnrollInChatCTA = ({
  explicitlyUnenrolledInChat,
  chatProfileId,
 }) => {
  return (
    <Paper
      sx={{ p: 0 }}
      variant="outlined"
    >
      <AvailabilityList
        aria-label="section1"
      >
        <ListItem
          style={{ marginBottom: '0px' }}
        >
          <Stack
            direction="column"
            spacing={1}
          >
            <Typography
              variant="body2"
            >
              You are not currently enrolled in Chat.
            </Typography>
            <Typography
              variant="body2"
            >
              {explicitlyUnenrolledInChat ? (
                <form
                  action={`/account/chat_profile/${chatProfileId}/reenroll`}
                  method="POST"
                  style={{ display: 'inline' }}
                >
                  <button
                    type="submit"
                    style={{
                      color: '#06c',
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Enroll in Chat
                  </button>
                </form>
              ) : (
                <a
                  href="/chat"
                  style={{ color: '#06c' }}
                >
                  Enroll in Chat
                </a>
              )}
            </Typography>
          </Stack>
        </ListItem>
      </AvailabilityList>
    </Paper>
  );
};

EnrollInChatCTA.defaultProps = {
  chatProfileId: null,
};

EnrollInChatCTA.propTypes = {
  explicitlyUnenrolledInChat: PropTypes.bool.isRequired,
  chatProfileId: PropTypes.number,
};

export default EnrollInChatCTA;
