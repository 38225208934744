import React from 'react';

import { styled } from '@mui/material/styles';

import {
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import AvailabilityShape from 'app/shapes/AvailabilityShape';

const FormControlFloats = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    '& .MuiTypography-root': {
      width: 'auto',
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      // minWidth: '240px',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

const CallMonitorToggle = ({ availability }) => {
  const [updateCallMonitor] = useUpdateAvailabilityMutation();

  const handleChangeCallMonitor = () => {
    const callMonitorOn = !availability?.call_monitor_on;
    updateCallMonitor({ availability: { call_monitor_on: callMonitorOn } });
  };

  return (
    <Grid test-data-id="call-monitor-toggle">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12}>
          <FormControlFloats
            value="start"
            control={(
              <Switch
                checked={!!availability?.call_monitor_on}
                name="call-monitor"
                sx={{ ml: 2 }}
                onChange={handleChangeCallMonitor}
              />
            )}
            label="Call Monitor"
            labelPlacement="start"
            sx={{ ml: 0 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CallMonitorToggle.defaultProps = {
  availability: {},
};

CallMonitorToggle.propTypes = {
  availability: AvailabilityShape,
};

export default CallMonitorToggle;
