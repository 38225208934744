import React from 'react';
import PropTypes from 'prop-types';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import PhoneShape from 'app/shapes/PhoneShape';
import AvailabilityShape from 'app/shapes/AvailabilityShape';

import {
  Box,
  Grid,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';

import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';
import AvailabilityList from 'app/redesign/components/availability/AvailabilityList';
import EnrollInChatCTA from './EnrollInChatCTA';

const CurrentChatPhoneStatus = ({
  phone,
  title,
  displaySecureNumber,
  zeroStateCopy,
  currentUser,
  availability,
}) => {
  const [updateCurrentChatPhoneStatus] = useUpdateAvailabilityMutation();
  const handleOnClick = (chatStatus) => {
    if (phone.chat_status === chatStatus) { return; }

    updateCurrentChatPhoneStatus({ availability: { chatStatus, phoneId: phone.id } });
  };
  const displayNumberMethod = displaySecureNumber ? 'secure_number' : 'unsecure_number';
  const {
    enrolled_in_chat: enrolledInChat,
    chat_statuses: chatStatuses,
    explicitly_unenrolled_in_chat: explicitlyUnenrolledInChat,
    chat_profile_id: chatProfileId,
  } = availability;
  const advisorNeedsToEnrollInChat = explicitlyUnenrolledInChat || (currentUser?.is_advisor && !enrolledInChat);

  let content = '';
  if (advisorNeedsToEnrollInChat) {
    content = (<EnrollInChatCTA chatProfileId={chatProfileId} explicitlyUnenrolledInChat={explicitlyUnenrolledInChat} />);
  } else if (!phone) {
    content = (
      <Paper test-data-id="no-verified-phone" sx={{ p: 2 }} variant="outlined">
        { zeroStateCopy }
      </Paper>
    );
  } else {
    content = (
      <Paper sx={{ p: 0 }} variant="outlined">
        <AvailabilityList aria-label="section1">
          { chatStatuses.map((status, index) => (
            <React.Fragment key={status.value}>
              <ListItem
                test-data-id={`${status.value}`}
                onClick={() => { handleOnClick(status.value); }}
              >
                <Stack style={{ width: '100%' }}>
                  <ListItemText primary={status.title} />
                  { status.title === 'Available - Chat Alerts On' && <ListItemText secondary={phone[displayNumberMethod]} sx={{ mt: -0.5 }} /> }
                </Stack>
                { (phone.chat_status === status.value || (status.value === 'online_only' && !phone?.is_current_chat_phone)) && (
                  <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', alignSelf: 'center' }}>
                    <CheckMarkNF sx={{ color: green[500] }} />
                  </ListItemIcon>
                )}
              </ListItem>
              { index !== (chatStatuses.length - 1) ? <Divider /> : null }
            </React.Fragment>
          ))}
        </AvailabilityList>
      </Paper>
    );
  }

  return (
    <Box test-data-id="current-chat-phone-status">
      <Grid container sx={{ mb: 1, alignItems: 'center', px: { xs: 3, sm: 0 }, pt: { xs: 1.5, sm: 0 } }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {title}
          </Typography>
        </Grid>
      </Grid>
      { content }
    </Box>
  );
};

CurrentChatPhoneStatus.defaultProps = {
  title: 'Chat Status',
  phone: null,
  displaySecureNumber: true,
  zeroStateCopy: 'No verified phone numbers on file. Click the Chat icon above to verify a phone number.',
  currentUser: {},
  availability: {},
};

CurrentChatPhoneStatus.propTypes = {
  title: PropTypes.string,
  phone: PhoneShape,
  displaySecureNumber: PropTypes.bool,
  zeroStateCopy: PropTypes.string,
  currentUser: PropTypes.object,
  availability: AvailabilityShape,
};

export default CurrentChatPhoneStatus;
