import React from 'react';
import PropTypes from 'prop-types';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import GoodyBagItemShape from 'app/shapes/GoodyBagItemShape';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

import GoodyBagItem from './goodyBagItems/GoodyBagItem';

const IncludedGoodyBagItems = ({ goodyBag, goodyBagItems, onGoodyBagItemClick }) => {
  return (
    <Box px={{ xs: 0.5, sm: 2 }} style={{ borderTop: '10px solid var(--color_ddd)' }}>
      <Accordion
        defaultExpanded
        className="container-goodies-included"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`goody-bag-${goodyBag.id}-content`}
          id={`goody-bag-${goodyBag.id}-header`}
        >
          <span className="subhead">Included Goodies</span>
        </AccordionSummary>
        <AccordionDetails className="included-scroll">
          <Grid container>
            {goodyBagItems.map((goodyBagItem) => <GoodyBagItem key={goodyBagItem.id} goodyBagItem={goodyBagItem} onGoodyBagItemClick={onGoodyBagItemClick} />)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

IncludedGoodyBagItems.defaultProps = {
  goodyBag: {},
  goodyBagItems: [],
};

IncludedGoodyBagItems.propTypes = {
  goodyBag: GoodyBagShape,
  goodyBagItems: PropTypes.arrayOf(GoodyBagItemShape),
  onGoodyBagItemClick: PropTypes.func.isRequired,
};

export default IncludedGoodyBagItems;
