import { useGetAppConfigQuery } from 'app/api/mainApi';

export const featureFlag = {
  enabled(flagName) {
    const appConfig = useGetAppConfigQuery().data;
    if (!appConfig) {
      return false;
    }
    const { feature_flags: featureFlags } = appConfig;
    return !!featureFlags[flagName.toUpperCase()];
  },

  getFlags() {
    const appConfig = useGetAppConfigQuery().data;
    if (!appConfig) {
      return {};
    }
    return appConfig.feature_flags;
  },
};
