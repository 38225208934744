import React from 'react';
import { PropTypes } from 'prop-types';

import { useAdvisorHomepageQuery } from 'app/api/mainApi';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const ProfileHtmlBox = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    fontSize: '0.938em',
    lineHeight: '1.5em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.000em',
      lineHeight: '1.5em',
    },
  },
}));

const Homepage = ({ advisorLogin }) => {
  const { data: homepageHTML, error: homepageError, isLoading: homepageIsLoading } = useAdvisorHomepageQuery({ login: advisorLogin });

  const homepageContentStyles = {
    overflow: 'hidden',
  };

  return (
    <ProfileHtmlBox
      p={{ xs: 1, sm: 2 }}
      pt={{ xs: 3, sm: 4 }}
    >
      {homepageHTML?.data?.toString()}
      {homepageIsLoading && 'Loading...'}
      {homepageError && 'Error loading homepage'}
      {homepageHTML?.content && (
        <Box id="redesign-homepage" sx={homepageContentStyles} dangerouslySetInnerHTML={{ __html: homepageHTML.content }} />
      )}
    </ProfileHtmlBox>
  );
};

Homepage.defaultProps = {
  advisorLogin: null,
};

Homepage.propTypes = {
  advisorLogin: PropTypes.string,
};

export default Homepage;
