import React from 'react';
import { PropTypes } from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const SendOneTimeTributeModal = ({
  amount,
  advisor,
  message,
  setMessage,
  setAmount,
  amountError,
  setAmountError,
  sendTributeClick,
  closeModal,
}) => {
  return (
    <>
      <div className="modal-header">
        <h4>
          Send a Tribute to&nbsp;
          {advisor.login}
        </h4>
      </div>
      <div className="modal-body">
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-xs-12">
            <TextField
              value={amount}
              type="number"
              inputMode="decimal"
              label="Tribute Amount"
              error={!!amountError}
              helperText={amountError}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(e) => {
                setAmountError('');
                setAmount(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="label-float">
              <textarea
                value={message}
                rows="4"
                style={{ padding: 10 }}
                onChange={(e) => { setMessage(e.currentTarget.value); }}
                className="tribute-textarea"
              />
              <label className={message ? 'filled' : ''}>
                Personal Message (optional)
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={closeModal}
        >
          Cancel
        </a>
        <button
          type="button"
          className="btn"
          onClick={sendTributeClick}
        >
          Send Tribute
        </button>
      </div>
    </>
  );
};

SendOneTimeTributeModal.propTypes = {
  advisor: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  setAmount: PropTypes.func.isRequired,
  amountError: PropTypes.string.isRequired,
  setAmountError: PropTypes.func.isRequired,
  sendTributeClick: PropTypes.func.isRequired,
};

export default SendOneTimeTributeModal;
