import {Box, Grid, Stack, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';
import {green} from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import React from 'react';
import ListAvailability from 'app/components/ListAvailability';

const CallStatusSelector = ({ selectedIndex, onIndexChange, phone }) => {

  const handleListItemClick = (event, index) => {
    onIndexChange(index);
  };

  return (
    <Box>
      <Grid container sx={{ mb: 1, alignItems: 'center', px: { xs: 3, sm: 0 } }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Call Status
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ p: 0 }} variant="outlined">
        <ListAvailability aria-label="section1">
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <Stack container style={{ width: '100%' }}>
              <ListItemText primary="Yes (Taking Calls)" />
              <ListItemText secondary={`${phone ? phone.secure_number : '(XXX) XXX-XXXX'}`} sx={{ mt: -0.5 }} />
            </Stack>
            <ListItemIcon align="right">
              <CheckMarkNF sx={{ color: green[500] }} />
            </ListItemIcon>
          </ListItemButton>
          <Divider />
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemText primary="Away (Not Taking Calls)" />
            <ListItemIcon align="right">
              <CheckMarkNF sx={{ color: green[500] }} />
            </ListItemIcon>
          </ListItemButton>
        </ListAvailability>
      </Paper>
    </Box>
  );
};

export default CallStatusSelector;