import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

import { featureFlag } from 'app/util/featureFlag';

const BackArrow = ({ darkAssets }) => {
  const { login } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const assetPath = darkAssets ? '/plus_frontend/assets/svg/ico-back-arrow-dark.svg' : '/plus_frontend/assets/svg/ico-back-arrow.svg';
  const feedToStoreFrontEnabled = featureFlag.enabled('FEED_TO_STOREFRONT_20571');

  // We don't want this to let us leave the Plus site- but I don't think there's any way
  //  to examine the history and prevent it.  But, good news, since the home feed doesn't
  //  have a back arrow, if we assume that people only enter Plus through the home feed, they
  //  can only go back this far and no farther.
  const backClick = () => {
    if (searchParams.get('fromCreate')) {
      // In one case, right after creating a post, back always takes us to the creator feed.
      feedToStoreFrontEnabled ? navigate(`${login}/feed`) : navigate(`/feed/${login}`);
    } else if (searchParams.get('fromLogin')) {
      // if we just came from login, redirect to the home feed
      navigate('/feed/home');
    } else {
      // since referrer sticks for the duration of the nf plus session, we can't
      // test where they came from, so we just go back.
      navigate(-1);
    }
  };

  return (
    <div className="nav-icon-sticky">
      <a role="link" tabIndex={0} onClick={backClick}>
        <img src={assetPath} alt="Back" />
      </a>
    </div>
  );
};

BackArrow.defaultProps = {
  darkAssets: false,
};

BackArrow.propTypes = {
  darkAssets: PropTypes.bool,
};

export default BackArrow;
