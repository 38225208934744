import React from 'react';
import { PropTypes } from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

import LikeButtonContainer from 'app/containers/LikeButtonContainer';
import TributeButtonContainer from 'app/containers/TributeButtonContainer';
import CommentLink from 'app/components/posts/postFooter/CommentLink';
import LikesCommentsCountsLink from 'app/components/posts/postFooter/LikesCommentsCountsLink';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import usePostUtilities from 'app/hooks/usePostUtilities';

const PostFooter = ({ advisor, post, showComments }) => {
  const { locked } = usePostUtilities({ post });
  const { data: { current_user: currentUser } = {} } = useGetAppConfigQuery();
  const isFeedToStorefrontEnabled = featureFlag.enabled('FEED_TO_STOREFRONT_20571');

  return (
    <div className="container-post-footer">
      <div className="row middle-xs">
        <div className="col-sm-8 col-xs-12">
          <div className="row middle-xs around-xs">
            <LikeButtonContainer post={post} locked={locked} />
            <div test-data-id="comment-button" className={`${locked ? 'locked-footer' : ''}`}>
              <CommentLink
                showComments={showComments}
                locked={locked}
                post={post}
                currentUser={currentUser}
              >
                <div className="row middle-xs">
                  <div>
                    <img src="/plus_frontend/assets/svg/ico-comments.svg" title="Comments" alt="Comments" />
                  </div>
                </div>
              </CommentLink>
            </div>
            <div>
              <TributeButtonContainer post={post} />
            </div>
            {advisor?.chat_eligible && (
              <div test-data-id="chat-button">
                <a
                  href={`/chat/${advisor?.slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="row middle-xs">
                    <div><img src="/plus_frontend/assets/svg/ico-chat.svg" title="Chat" alt="Chat" /></div>
                  </div>
                </a>
              </div>
            )}
            <div test-data-id="call-button">
              {isFeedToStorefrontEnabled ? (
                <a
                  href={post ? `/${post.account.login}` : '#'}
                  rel="noreferrer"
                >
                  <div className="row middle-xs">
                    <div><img src="/plus_frontend/assets/svg/ico-call.svg" title="Call" alt="Call" /></div>
                  </div>
                </a>
              ) : (
                <a
                  href={post ? `/profile/${post.account.login}#live` : '#'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="row middle-xs">
                    <div><img src="/plus_frontend/assets/svg/ico-call.svg" title="Call" alt="Call" /></div>
                  </div>
                </a>
              )}
            </div>
          </div>

          {(post.comments_count > 0 || post.likes_count > 0) && (
            <div className="col-sm-12 col-xs-12 post-footer-2ndpart">
              <div className="row middle-xs">
                <LikesCommentsCountsLink
                  type="likes"
                  post={post}
                  currentUser={currentUser}
                />
                {post.comments_count > 0 && post.likes_count > 0 && (
                  <div className="paddinglft10 paddingrgt10">•</div>
                )}
                <LikesCommentsCountsLink
                  type="comments"
                  post={post}
                  currentUser={currentUser}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PostFooter.defaultProps = {
  advisor: null,
  post: null,
};

PostFooter.propTypes = {
  advisor: PropTypes.object,
  post: PropTypes.object,
  showComments: PropTypes.bool.isRequired,
};
export default PostFooter;
