import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { useLazyGetGoodyBagItemsQuery, useLazyGetGoodyBagReviewsQuery } from 'app/api/assetApi';

import { Box, Grid, Link, Typography } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import useFlirtMedia from 'app/hooks/useFlirtMedia';
import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';
import RightColumn from 'app/components/shared/RightColumn';
import NameTypography from 'app/components/shared/NameTypography';
import DescBox from 'app/components/shared/DescBox';
import RatingContainer from 'app/components/shared/RatingContainer';

import { toCurrency } from 'app/helpers/currencyHelpers';
import useGoodyBagActions from 'app/hooks/useGoodyBagActions';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';
import GoodyBagDetailsDrawer from 'app/redesign/components/modals/GoodyBagDetailsDrawer';
import GoodyBagReviewsList from 'app/redesign/components/modals/GoodyBagReviewsList';
import ProfileContext from 'app/contexts/ProfileContext';
import SanitizedHTML from 'app/helpers/SanitizedHTML';

const GoodyBagCard = ({
  goodyBag,
  viewingSelf,
  availableBalance,
  currentUser,
  advisorLoading,
  selected,
  advisor,
}) => {
  const [triggerGoodyBagItemsQuery, goodyBagItemsResult] = useLazyGetGoodyBagItemsQuery({ goodyBagId: goodyBag.id });
  const goodyBagItems = goodyBagItemsResult?.data || [];
  const params = useParams();
  const { action } = useContext(ProfileContext);

  const [triggerGoodyBagReviewsQuery, goodyBagReviewsResult] = useLazyGetGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
  const goodyBagReviews = goodyBagReviewsResult?.data || [];

  // Lightbox Management
  const mediaItems = goodyBagItems
    .filter(item => item.previewable)
    .map((goodyBagItem) => goodyBagItemToMediaParams(goodyBagItem));
  const {
    currentItem,
    onCloseClick,
    setMediaIndex,
    lightboxIsOpen,
    nextItem,
    prevItem,
    onNextClick,
    onPrevClick,
    setLightboxIsOpen,
    pauseAllVideoPlayers,
    pauseAllAudioPlayers,
    lightboxMediaDecorator,
  } = useFlirtMedia({ mediaItems });

  const onGoodyBagItemClick = (goodyBagItem) => {
    const selectedMediaItemIndex = mediaItems.findIndex(item => item.id === goodyBagItem.id);
    setMediaIndex(selectedMediaItemIndex);
    setLightboxIsOpen(true);
  };

  const pauseAllPlayers = () => {
    pauseAllAudioPlayers();
    pauseAllVideoPlayers();
  };

  const onPreviewClose = () => {
    pauseAllPlayers();
    onCloseClick();
  };

  // Drawer Management
  const drawerAnchor = 'right';

  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(!!selected);
  const [isRatingsDrawerOpen, setIsRatingsDrawerOpen] = useState(false);

  const { onGoodyBagButtonClick } = useGoodyBagActions({
    goodyBag,
    viewingSelf,
    availableBalance,
    currentUser,
    advisorLoading,
    advisor,
  });

  const ignoreCloseDrawerEvents = (event) => {
    return event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift');
  };

  const openDetailsDrawer = () => {
    setIsDetailsDrawerOpen(true);
  };

  const closeDetailsDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    onPreviewClose();
    setIsDetailsDrawerOpen(false);
  };

  const openRatingsDrawer = () => {
    pauseAllPlayers();
    triggerGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
    setIsRatingsDrawerOpen(true);
  };

  const closeRatingsDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    setIsRatingsDrawerOpen(false);
  };

  useEffect(() => {
    if (isDetailsDrawerOpen) {
      triggerGoodyBagItemsQuery({ goodyBagId: goodyBag.id });
    }
  }, [isDetailsDrawerOpen]);

  // If they tried deep linking to this specific goody bag, open the details drawer.
  // But not if they're trying to buy it, because clicking buy in the drawer would
  // close the drawer anyway.
  useEffect(() => {
    if (goodyBag.id === parseInt(params.goodyBagId)) {
      if (action === 'buy') {
        onGoodyBagButtonClick();
      } else {
        openDetailsDrawer();
      }
    }
  }, []);

  const renderRating = () => {
    if (!goodyBag.total_ratings === 0) {
      return <Typography sx={{ color: '#aaa', fontSize: '0.813em' }}>Not Yet Rated</Typography>;
    }

    return (
      <>
        <ThumbUpAltIcon style={{ color: '#aaa', fontSize: 20, marginRight: '5px' }} />
        <Typography sx={{ color: '#aaa', fontSize: '0.813em' }}>
          <Link href="#" sx={{ color: '#aaa' }} onClick={openRatingsDrawer}>
            {goodyBag.approval}
            %
          </Link>
        </Typography>
      </>
    );
  };

  return (
    <>
      <StyledCard>
        <Grid container spacing={0}>
          <Grid item>
            <Box
              sx={{
                width: '150px',
                height: '180px',
                position: 'absolute',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1',
              }}
            >
              <img src={goodyBag.thumbnail_url} alt="Card" style={{ width: '100%', height: '100%', objectFit: 'contain' }} loading="lazy" />
            </Box>
            <BlurBox style={{ backgroundImage: `url(${goodyBag.thumbnail_url})` }} />
          </Grid>
          <RightColumn item>
            <NameTypography variant="h6">{goodyBag.title}</NameTypography>
            <DescBox className="description">
              <a href="#" onClick={openDetailsDrawer}><SanitizedHTML html={goodyBag.description} /></a>
            </DescBox>
            <RatingContainer>
              {renderRating()}
            </RatingContainer>
            <Grid container alignItems="center" style={{ padding: '0px', marginTop: '10px' }}>
              <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', height: '34px' }}>
                <AuthenticatedLink
                  role="dialog"
                  onClick={onGoodyBagButtonClick}
                  className="buy-now-button buy-now goody"
                  returnUrl={`/${advisor?.login}/goodies/${goodyBag?.id}/buy`}
                >
                  <span>Buy Now</span>
                </AuthenticatedLink>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '7px' }}>
                <Typography variant="body3" sx={{ color: '#999', fontSize: '0.813em' }}>
                  <span style={{ color: '#fff', display: 'inline-block' }}>
                    {toCurrency(goodyBag.price)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </RightColumn>
        </Grid>
      </StyledCard>
      <GoodyBagDetailsDrawer
        anchor={drawerAnchor}
        goodyBag={goodyBag}
        goodyBagItems={goodyBagItems}
        previewableItems={mediaItems}
        isOpen={isDetailsDrawerOpen}
        closeDrawer={closeDetailsDrawer}
        openRatingsDrawer={openRatingsDrawer}
        closeRatingsDrawer={closeRatingsDrawer}
        isRatingsDrawerOpen={isRatingsDrawerOpen}
        pauseAllPlayers={pauseAllPlayers}
        advisor={advisor}
        onGoodyBagItemClick={onGoodyBagItemClick}
        onBuyGoodyBagButtonClick={onGoodyBagButtonClick}
      />
      <GoodyBagReviewsList
        goodyBag={goodyBag}
        currentUser={currentUser}
        goodyBagReviews={goodyBagReviews}
        isDrawerOpen={isRatingsDrawerOpen}
        closeDrawer={closeRatingsDrawer}
        openDrawer={openRatingsDrawer}
        isLoadingReviews={goodyBagReviewsResult?.isLoading}
      />
      {lightboxIsOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
          // we should always use the image thumbnail; video and other fullsize media
          //  are only available if you've purchased the goody bag and are viewing it
          //  in the file manager
          mainSrc={lightboxMediaDecorator(currentItem, 'thumb')}
          nextSrc={lightboxMediaDecorator(nextItem, 'thumb')}
          prevSrc={lightboxMediaDecorator(prevItem, 'thumb')}
          onCloseRequest={onPreviewClose}
          onMovePrevRequest={onPrevClick}
          onMoveNextRequest={onNextClick}
        />
      )}
    </>
  );
};

GoodyBagCard.defaultProps = {
  availableBalance: 0,
  currentUser: {},
  advisor: {},
};

GoodyBagCard.propTypes = {
  goodyBag: GoodyBagShape.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  availableBalance: PropTypes.number,
  currentUser: PropTypes.object,
  advisorLoading: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
  selected: PropTypes.bool.isRequired,
};

export default GoodyBagCard;
