import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePubNub } from 'pubnub-react';
import { Box, Grid, Typography, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

import { toCurrency } from 'app/helpers/currencyHelpers';
import { callButton } from 'app/helpers/listingHelpers';
import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';
import RightColumn from 'app/components/shared/RightColumn';
import NameTypography from 'app/components/shared/NameTypography';
import DescBox from 'app/components/shared/DescBox';
import RatingContainer from 'app/components/shared/RatingContainer';
import SanitizedHTML from 'app/helpers/SanitizedHTML';

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

const CustomRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ffb400', // Yellow color for filled stars
  },
  '& .MuiRating-iconEmpty': {
    color: '#999', // Gray outline for empty stars
  },
  '& .MuiRating-icon': {
    fontSize: '1em', // Adjust the font size of the stars if needed
  },
});

const PhoneListingCard = ({ listing }) => {
  const pubnub = usePubNub();
  const truncatedHeading = truncateText(listing.full_category, 20);
  const truncatedTitle = truncateText(listing.title, 50);
  const listingUrl = `/listings/show/${listing.slug}`;

  const channels = [listing.push_channel];
  // subscribe on load, and unsubscribe on unload
  useEffect(() => {
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
    };
  }, []);

  return (
    <StyledCard>
      <Grid container spacing={0}>
        <Grid item>
          <Box
            sx={{
              width: '150px',
              height: '180px',
              position: 'absolute',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1',
            }}
          >
            <img
              src={listing.photo_url}
              alt="Card"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Box>
          <BlurBox style={{ backgroundImage: `url(${listing.photo_url})` }} />
        </Grid>
        <RightColumn item>
          <NameTypography variant="h6">{truncatedHeading}</NameTypography>
          <DescBox className="description">
            <a href={listingUrl}>
              <SanitizedHTML html={truncatedTitle} />
            </a>
          </DescBox>
          <RatingContainer>
            <CustomRating
              name="rating"
              value={listing.rating}
              precision={0.5}
              readOnly
              size="small"
            />
            <Typography
              sx={{
                margin: '-1px 0 0 10px',
                color: '#aaa',
                fontSize: '0.813em',
              }}
            >
              ({listing.rating})
            </Typography>
          </RatingContainer>
          <Grid
            container
            alignItems="center"
            style={{ padding: '0px', marginTop: '10px' }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {callButton(listing)}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '7px',
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: '#999', fontSize: '0.813em' }}
              >
                <span style={{ color: '#fff', display: 'inline-block' }}>
                  {toCurrency(listing.price_per_minute)}
                </span>
                <span style={{ display: 'inline-block' }}>&nbsp;per min.</span>
              </Typography>
            </Grid>
          </Grid>
        </RightColumn>
      </Grid>
    </StyledCard>
  );
};

PhoneListingCard.defaultProps = {};

PhoneListingCard.propTypes = {
  listing: PropTypes.object.isRequired,
};

export default PhoneListingCard;
