import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';
import AvailabilityShape from 'app/shapes/AvailabilityShape';

import { DateTime } from 'luxon';

import {
  Stack,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import QuietHoursToggle from 'app/redesign/components/availability/QuietHoursToggle';
import QuietHourTimePicker from 'app/redesign/components/availability/QuietHourTimePicker';

import {
  useUpdateAvailabilityMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

const BoxTimePicker = styled(Box)(() => ({
  '& .MuiInputBase-input': {
    padding: '0.625em 0.750em',
  },
  '& .MuiFormLabel-root': {
    overflow: 'inherit',
    lineHeight: '0.7em',
  },
  '& .MuiInputLabel-shrink': {
    overflow: 'inherit',
    marginTop: '5px',
  },
  '& .MuiStack-root>.MuiTextField-root': {
    minWidth: '144px',
  },
  '& .MuiOutlinedInput-root': {
    maxWidth: '144px',
  },
}));

const QuietHours = ({ availability }) => {
  const dispatch = useDispatch();
  const [updateAvailability, { isError }] = useUpdateAvailabilityMutation();
  const appConfig = useGetAppConfigQuery().data;
  const currentUser = appConfig?.current_user;

  const updateAvailabilityFor = (attributeName, timeValue, context) => {
    if (context.validationError === null) {
      const twentyFourHourValue = timeValue.hour;
      updateAvailability({ availability: { [attributeName]: twentyFourHourValue } });
    }
  };

  const quietHoursToDateTime = (hour) => {
    const now = DateTime.now();
    if (hour === undefined || hour === null) return now;

    return now.set({ hour, minute: 0 });
  };

  useEffect(() => {
    if (isError) {
      dispatch(showSnackbarMessage({
        message: 'There was an error updating quiet hours. Please try again.  We have been notified.',
        severity: 'error',
      }));
    }
  }, [isError]);

  if (availability?.explicitly_unenrolled_in_chat) {
    return null;
  }

  if (currentUser?.should_hide_availability_options) {
    return (
      <Box sx={{ m: 2 }} test-data-id="quiet-hours">
        <div>
          Quiet Hours are only available when Chat Status is on.
        </div>
      </Box>
    );
  }

  return (
    <Box test-data-id="quiet-hours">
      <QuietHoursToggle availability={availability} />
      <Grid container sx={{ alignItems: 'center', paddingLeft: { xs: 3, sm: 0 } }}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center" // This aligns items vertically in the center
            sx={{ ml: { xs: -0.5, sm: 0 }, height: '60px' }}
          >
            <BoxTimePicker test-data-id="quiet-hours-start">
              <QuietHourTimePicker
                value={quietHoursToDateTime(availability?.quiet_hours_start_local)}
                onChange={(timeValue, context) => { updateAvailabilityFor('chat_quiet_hours_start_local', timeValue, context); }}
              />
            </BoxTimePicker>
            <Box>to</Box>
            <BoxTimePicker test-data-id="quiet-hours-end">
              <QuietHourTimePicker
                value={quietHoursToDateTime(availability?.quiet_hours_end_local)}
                onChange={(timeValue, context) => { updateAvailabilityFor('chat_quiet_hours_end_local', timeValue, context); }}
              />
            </BoxTimePicker>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-start' }, padding: '10px 0px' }}>
            <Typography variant="body3">
              <span
                test-data-id="current-timezone"
                style={{ color: '#999' }}
              >
                {`Current Timezone - ${currentUser?.timezone}`}
              </span>
            </Typography>
            <Typography variant="body3">
              <a href={`/users/${currentUser?.id}/edit`}>
                Update
              </a>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

QuietHours.defaultProps = {
  availability: {},
};

QuietHours.propTypes = {
  availability: AvailabilityShape,
};

export default QuietHours;
