import React from 'react';
import { PropTypes } from 'prop-types';
import { toCurrency } from 'app/helpers/currencyHelpers';

const TributeSentModal = ({
  amount,
  advisorName,
  closeModal,
  resetTributeFields,
  isRecurring,
}) => {
  return (
    <>
      <div className="modal-header">
        <h4>
          {isRecurring && 'Recurring '}
          Tribute Sent
        </h4>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-xs-12">
            <p>
              Your tribute in the amount of&nbsp;
              {toCurrency(amount)}
              &nbsp;has been successfully sent to&nbsp;
              {advisorName}
              {isRecurring && ' and will be sent monthly'}
              .
            </p>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn"
          onClick={() => {
            resetTributeFields();
            closeModal();
            window.dispatchEvent(new CustomEvent('tributeSentSuccessfully', {}));
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

TributeSentModal.defaultProps = {
  isRecurring: false,
};

TributeSentModal.propTypes = {
  amount: PropTypes.string.isRequired,
  advisorName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  resetTributeFields: PropTypes.func.isRequired,
  isRecurring: PropTypes.bool,
};

export default TributeSentModal;
