import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { green } from '@mui/material/colors';
import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';

const CallStatusMenuItem = ({ onClick, selected, primaryText, secondaryText }) => {
  return (
    <ListItemButton
      selected={selected}
      onClick={onClick}
      test-data-id={`call-status-menu-item-${primaryText}`}
    >
      <Stack style={{ width: '100%' }}>
        <ListItemText primary={primaryText} />
        <ListItemText secondary={secondaryText} sx={{ mt: -0.5 }} />
      </Stack>
      <ListItemIcon sx={{ display: 'flex !important', justifyContent: 'flex-end' }}>
        { selected && <CheckMarkNF sx={{ color: green[500] }} /> }
      </ListItemIcon>
    </ListItemButton>
  );
};

CallStatusMenuItem.defaultProps = {
  secondaryText: '',
};

CallStatusMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
};

export default CallStatusMenuItem;
