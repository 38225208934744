import React from 'react';
import PropTypes from 'prop-types';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import SearchControlsContainer from 'app/containers/SearchControlsContainer';
import SearchToggle from './SearchToggle';
import SearchContainer from 'app/containers/SearchContainer';
import HomeFeedTabs from './nav/HomeFeedTabs';

const Home = ({ children }) => {
  return (
    <AppBodyWithSidebar>
      <SearchContainer>
        <div className="container-hdr-sticky2">
          <div className="row middle-xs start-xs nowrap-xs">
            <div className="col-xs-10">
              <div className="nav-icon-sticky">
                <a href="#">
                  <img src="/plus_frontend/assets/svg/ico-feed-sticky.svg" alt="Feed" className="home-sticky" />
                </a>
              </div>
              <h2>Feed</h2>
            </div>
            <SearchToggle />
          </div>
        </div>
        <SearchControlsContainer />
      </SearchContainer>
      <HomeFeedTabs />
      {children}
    </AppBodyWithSidebar>
  );
};

Home.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Home;
