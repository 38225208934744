import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  useCreateBonusMutation,
  useGetAdvisorQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import useCreditCardManagement from 'app/hooks/useCreditCardManagement';
import ModalsContext from 'app/contexts/ModalsContext';
import TributeSentModal from 'app/components/modals/TributeSentModal';
import { userHasCard } from 'app/helpers/userHelper';
import { showFlash } from 'app/slices/flashSlice';
import { setTributeAmount, setTributeMessage } from 'app/slices/tributesSlice';
import SendOneTimeTributeModal from 'app/components/modals/SendOneTimeTributeModal';

const SendOneTimeTributeModalContainer = ({
  advisorLogin,
}) => {
  const dispatch = useDispatch();
  const { tributeAmount } = useSelector(state => state.tributes);
  const { tributeMessage } = useSelector(state => state.tributes);
  const [createBonus, createBonusResult] = useCreateBonusMutation();
  const [amountError, setAmountError] = useState('');
  const { openModal, closeModal, closeSelfAndOpenModal } = useContext(ModalsContext);
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const currentUserHasCard = userHasCard(currentUser);

  const {
    data: advisor,
    isLoading: advisorLoading,
  } = useGetAdvisorQuery({ login: advisorLogin });

  const setAmount = (tributeAmount) => {
    dispatch(setTributeAmount({ tributeAmount }));
  };

  const setMessage = (tributeMessage) => {
    dispatch(setTributeMessage({ tributeMessage }));
  };

  const inputAmountInvalid = () => {
    return tributeAmount.match(/^[0-9]*(\.[0-9]{1,2})?$/) === null;
  };

  const sendTributeClick = () => {
    if (advisorLoading) return;

    if (inputAmountInvalid()) {
      setAmountError('Please enter a valid amount without commas or currency symbols.');
      return;
    }

    const listingId = advisor.top_listing_id;
    const numericAmount = parseFloat(tributeAmount);
    const fromNfChat = window.location.pathname.includes('/chat');

    createBonus({
      listingId,
      message: tributeMessage,
      amount: numericAmount,
      fromNfChat,
    });
  };

  const onAddToBalanceSuccess = () => {
    openModal({
      component: SendOneTimeTributeModalContainer,
      props: {
        advisorLogin,
        afterCloseAction: null,
      },
    });
  };

  let openAddToBalanceModalAlias;

  const onManageCardsSuccess = () => {
    openAddToBalanceModalAlias();
  };

  const onTryAgainSuccess = () => {};

  const {
    openManageCardsModal,
    openAddToBalanceModal,
  } = useCreditCardManagement({
    openModal,
    currentUser,
    guard: null,
    setGuard: null,
    onTryAgainSuccess,
    onManageCardsSuccess,
    onAddToBalanceSuccess,
  });

  openAddToBalanceModalAlias = openAddToBalanceModal;

  const resetTributeFields = () => {
    setAmount('');
    setMessage('');
  };

  const handleCreateBonusSuccess = () => {
    closeSelfAndOpenModal({
      component: TributeSentModal,
      props: {
        advisorName: advisorLogin,
        amount: tributeAmount,
        resetTributeFields,
      },
    });
  };

  const handleCreateBonusError = (data) => {
    const { errors, deficit } = data;

    // "Payment Amount should be from $0.05 to $999" Exception
    if (errors.payment_amount && errors.payment_amount.length > 0) {
      setAmountError(errors.payment_amount[0]);
    }

    if (errors.amount && errors.amount[0] === 'You don\'t have enough money in your account.') {
      closeModal();

      if (currentUserHasCard) {
        openAddToBalanceModal(deficit, null, 'tribute');
      } else {
        openManageCardsModal();
      }
    } else if (errors.not_send_to_denied_recipients) {
      closeModal();

      dispatch(showFlash({
        flashType: 'error',
        props: { message: errors.not_send_to_denied_recipients[0] },
      }));
    }
  };

  useEffect(() => {
    if (createBonusResult.isSuccess) {
      handleCreateBonusSuccess(createBonusResult.data);
    }

    if (
      createBonusResult.isError &&
      createBonusResult.error.status === 422
    ) {
      handleCreateBonusError(createBonusResult.error.data);
    }
  }, [createBonusResult]);

  if (!advisor) return null;

  return (
    <SendOneTimeTributeModal
      amount={tributeAmount}
      advisor={advisor}
      message={tributeMessage}
      setMessage={setMessage}
      setAmount={setAmount}
      amountError={amountError}
      setAmountError={setAmountError}
      sendTributeClick={sendTributeClick}
      closeModal={closeModal}
    />
  );
};

SendOneTimeTributeModalContainer.propTypes = {
  advisorLogin: PropTypes.string.isRequired,
};

export default SendOneTimeTributeModalContainer;
