import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useMatch } from 'react-router-dom';

import FlirtContentContainer from 'app/containers/FlirtContentContainer';
import PostDetailContainer from 'app/containers/PostDetailContainer';
import MySubscriptionsContainer from 'app/containers/MySubscriptionsContainer';
import CreatePostContainer from 'app/containers/CreatePostContainer';
import MobileSubscriptions from 'app/components/subscriptions/MobileSubscriptions';
import PostsQueueContainer from './PostsQueueContainer';

const StorefrontFeedContainer = ({ advisor }) => {
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const viewingMedia = useMatch(`/${encodedAdvisorLogin}/feed/media`);
  const viewingSubscriptions = useMatch(`/${encodedAdvisorLogin}/feed/subscriptions`);
  const viewingNewPost = useMatch(`/${encodedAdvisorLogin}/feed/create_post`);

  const queueRoutes = [
    useMatch(`/${encodedAdvisorLogin}/feed/queue/scheduled`),
    useMatch(`/${encodedAdvisorLogin}/feed/queue/processing`),
    useMatch(`/${encodedAdvisorLogin}/feed/queue/removed`),
  ];

  const viewingQueue = queueRoutes.filter((val) => val !== null).length > 0;
  const { postId } = useParams();

  if (Number.isInteger(parseInt(postId))) {
    return <PostDetailContainer selectedPostId={postId} hideHeader />;
  }

  if (viewingSubscriptions) {
    return <MySubscriptionsContainer />;
  }

  if (viewingNewPost) {
    return <CreatePostContainer />;
  }

  if (viewingQueue) {
    return <PostsQueueContainer />;
  }

  return (
    <>
      <MobileSubscriptions advisorLogin={advisor?.login} />
      <FlirtContentContainer showMediaTab={viewingMedia} />
    </>
  );
};

StorefrontFeedContainer.defaultProps = {
  advisor: {},
};

StorefrontFeedContainer.propTypes = {
  advisor: PropTypes.object,
};

export default StorefrontFeedContainer;
