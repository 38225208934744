import React from 'react';
import { PropTypes } from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup } from '@mui/material';

const DeprecatedNewTributeForm = ({
  advisor,
  recurring,
  setRecurringClick,
  amount,
  amountInvalid,
  setAmount,
  message,
  setMessage,
  amountError,
  setAmountError,
  label,
  sendTributeClick,
  closeClick,
  isLoading,
}) => {
  return (
    <>
      <DialogTitle>
        Send a Tribute to&nbsp;
        {advisor.login}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeClick}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box sx={{ marginLeft: { xs: 0, sm: 4 }, marginRight: { xs: 0, sm: 4 } }}>
          <Grid container rowSpacing={2}>
            <Grid container xs={12} justifyContent="flex-end">
              <ButtonGroup
                variant="contained"
                aria-label="Basic button group"
                size="small"
              >
                <Button
                  className={!recurring ? 'active' : ''}
                  onClick={() => setRecurringClick(false)}
                >
                  One-time
                </Button>
                <Button
                  className={recurring ? 'active' : ''}
                  onClick={() => setRecurringClick(true)}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={amount}
                type="number"
                inputMode="decimal"
                label={label}
                error={!!amountError}
                helperText={amountError}
                test-data-id="tribute-amount"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e) => {
                  setAmountError('');
                  setAmount(e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={message}
                test-data-id="tribute-message"
                label="Personal Message (optional)"
                onChange={(e) => { setMessage(e.currentTarget.value); }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  onClick={closeClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={amountInvalid(amount) || isLoading}
                  onClick={sendTributeClick}
                >
                  Send Tribute
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
};

DeprecatedNewTributeForm.propTypes = {
  advisor: PropTypes.object.isRequired,
  recurring: PropTypes.bool.isRequired,
  setRecurringClick: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  amountInvalid: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  amountError: PropTypes.string.isRequired,
  setAmountError: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  sendTributeClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DeprecatedNewTributeForm;
