import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import ModalsContext from 'app/contexts/ModalsContext';

const ModalWrapperComponent = ({
  modalIndex,
  ModalComponent,
  modalProps,
  closeModal,
  closeAllModals,
  willNotCloseLowerModals,
  hideCloseButton,
  setDenyCloseModalRequests,
  noDefaultBody,
  modalContentStyles,
}) => {
  if (!ModalComponent) { return null; }

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const closeModalWithConfirmation = () => {
    if (unsavedChanges) {
      const msg = 'You have unsaved changes. Is this OK? If not, select Cancel to keep editing.';
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!confirm(msg)) return;
    }

    // we need to remove the event listener for the current modal, and add back the previous one, if there is one.
    // modal close functions are stored in window, because we need them to persist and never be recreated
    // in order for removeEventListener to work properly.
    document.removeEventListener('keydown', window.closeFunctions[modalIndex]);
    delete (window.closeFunctions[modalIndex]);

    if (window.closeFunctions && Object.keys(window.closeFunctions)[0]) {
      const previousCloseFunction = window.closeFunctions[Math.max(...Object.keys(window.closeFunctions))];
      document.addEventListener('keydown', previousCloseFunction);
    }

    (willNotCloseLowerModals ? closeModal : closeAllModals)();
  };

  // remove previous event listener if there was one, leave it in storage, and add our new one.
  // modal close functions are stored in window, because we need them to persist and never be recreated
  // in order for removeEventListener to work properly.
  useEffect(() => {
    if (!window.closeFunctions) window.closeFunctions = {};
    // if there are any custom close functions,
    if (Object.keys(window.closeFunctions)[0]) {
      // then find the one for the most recently rendered modal
      const previousCloseFunction = window.closeFunctions[Math.max(...Object.keys(window.closeFunctions))];
      // and remove its listener
      document.removeEventListener('keydown', previousCloseFunction);
    }

    window.closeFunctions[modalIndex] = (e) => { if (e?.keyCode === 27) closeModalWithConfirmation(); };
    document.addEventListener('keydown', window.closeFunctions[modalIndex]);
  }, [unsavedChanges]);

  if (noDefaultBody) {
    return (
      <ModalComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalProps}
        closeModal={closeModalWithConfirmation}
        setUnsavedChanges={setUnsavedChanges}
        setDenyCloseModalRequests={setDenyCloseModalRequests}
      />
    );
  }

  return (
    <div className="nfp-modal">
      <div className="modal-content" style={modalContentStyles}>
        <div className="modal-close-container" style={{ visibility: (hideCloseButton ? 'hidden' : 'visible') }}>
          <a role="link" tabIndex={0} onClick={closeModalWithConfirmation}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        { /* eslint-disable react/jsx-props-no-spreading */ }
        <ModalComponent
          {...modalProps}
          closeModal={closeModalWithConfirmation}
          closeAllModals={closeAllModals}
          setUnsavedChanges={setUnsavedChanges}
          setDenyCloseModalRequests={setDenyCloseModalRequests}
        />
        { /* eslint-enable react/jsx-props-no-spreading */ }
      </div>
    </div>
  );
};

ModalWrapperComponent.defaultProps = {
  ModalComponent: null,
  modalProps: null,
  closeModal: null,
  callToAction: null,
  hideCloseButton: false,
  setDenyCloseModalRequests: null,
  noDefaultBody: false,
  modalContentStyles: null,
  willNotCloseLowerModals: false,
};

ModalWrapperComponent.propTypes = {
  ModalComponent: PropTypes.func,
  modalProps: PropTypes.object,
  closeModal: PropTypes.func,
  callToAction: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  setDenyCloseModalRequests: PropTypes.func,
  noDefaultBody: PropTypes.bool,
  modalContentStyles: PropTypes.object,
  modalIndex: PropTypes.number.isRequired,
  willNotCloseLowerModals: PropTypes.bool,
  closeAllModals: PropTypes.func.isRequired,
};

const ModalsContainer = ({ children }) => {
  const [modalStack, setModalStack] = useState([]);
  const [denyCloseModalRequests, setDenyCloseModalRequests] = useState(false);

  // this doesn't appear to run anymore
  // too bad, because overriding custom close function with this
  // argument could be useful in some cases
  const closeModal = (runCustomCloseFunc = true) => {
    if (denyCloseModalRequests) return;
    if (modalStack.length === 0) return;

    const topModal = modalStack.slice(-1)[0];
    if (topModal?.customCloseFunc && runCustomCloseFunc) {
      topModal.customCloseFunc();
    }

    const newModalStack = [...modalStack];
    newModalStack.pop();

    setModalStack(newModalStack);
  };

  const closeAllModals = (runCustomCloseFunc = true) => {
    // go through the current stack in reverse order, and check each one for a custom close function
    // if there is one, call it.
    modalStack.slice().reverse().forEach((modal) => {
      if (modal?.customCloseFunc && runCustomCloseFunc) {
        modal.customCloseFunc();
      }
    });

    setModalStack([]);
  };

  // example modalProps: {
  //   component: null,
  //   props: null,
  //   hideCloseButton: false,
  //   customCloseFunc: null,
  //   noDefaultBody: false,
  //   modalContentStyles: null,
  // }
  const openModal = (modalProps) => {
    setModalStack([...modalStack, modalProps]);
  };

  const closeSelfAndOpenModal = (openModalProps) => {
    // this calls a setModalStack which is overridden below;
    // but it's still worth doing to trigger any custom close functions
    closeModal();

    setModalStack([openModalProps]);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalsContext.Provider value={{ openModal, closeModal, closeAllModals, closeSelfAndOpenModal }}>
      { modalStack.map((modalState, index) => (
        <ModalWrapperComponent
          key={`ModalWrapper-${index}`} // eslint-disable-line react/no-array-index-key
          modalIndex={index}
          ModalComponent={modalState.component}
          noDefaultBody={modalState.noDefaultBody}
          modalProps={modalState.props}
          closeModal={closeModal}
          closeAllModals={closeAllModals}
          willNotCloseLowerModals={modalState.willNotCloseLowerModals}
          hideCloseButton={modalState.hideCloseButton}
          setDenyCloseModalRequests={setDenyCloseModalRequests}
          modalContentStyles={modalState.modalContentStyles}
        />
      )) }
      {children}
    </ModalsContext.Provider>
  );
};

ModalsContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ModalsContainer;
