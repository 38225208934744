import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';
import FavoriteListingCard from 'app/components/favorite_listings/FavoriteListingCard';

const FlexGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: '6px',
  paddingTop: '6px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start', // Default value for all breakpoints
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start', // Change for small (sm) and up breakpoints
  },
}));

const FavoriteListings = () => {
  const { favoriteListingsData, isUpdating, isSortable, updateFavoriteListing, selectedListings } = useContext(FavoriteListingsContext);
  const { favorite_listings: favoriteListings } = favoriteListingsData;
  const [localFavoriteListings, setLocalFavoriteListings] = useState(favoriteListings);

  useEffect(() => {
    setLocalFavoriteListings(favoriteListings);
  }, [favoriteListings]);

  const updateListingsOrder = (sourceIndex, destinationIndex) => {
    updateFavoriteListing({
      id: localFavoriteListings[sourceIndex].id,
      position: localFavoriteListings[destinationIndex].position,
    });
    const newFavoriteListings = localFavoriteListings.map(item => ({ ...item }));
    const [reorderedItem] = newFavoriteListings.splice(sourceIndex, 1);
    newFavoriteListings.splice(destinationIndex, 0, reorderedItem);
    // mark the positions as invalid until we get server response
    newFavoriteListings.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.position = 0;
    });
    setLocalFavoriteListings(newFavoriteListings);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || (source.index === destination.index && source.droppableId === destination.droppableId)) {
      return;
    }
    updateListingsOrder(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="favorite_listings">
        {(provided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <FlexGrid container {...provided.droppableProps} ref={provided.innerRef}>
            {localFavoriteListings.map((favoriteListing, index) => (
              <Draggable key={favoriteListing.id} draggableId={favoriteListing.id.toString()} index={index} isDragDisabled={!isSortable || isUpdating}>
                {(provided) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <FavoriteListingCard
                      favoriteListing={favoriteListing}
                      checked={selectedListings[favoriteListing.listing_id] === true}
                    />
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </FlexGrid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FavoriteListings;
