import React from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import styled from '@mui/material/styles/styled';
import { Pagination, PaginationItem } from '@mui/material';

import ListingCard from './ListingCard';
import Hero from './Hero';
import TollFreeAdvert from './TollFreeAdvert';
import StayConnected from './StayConnected';

const NumberlessPagination = styled(Pagination)(({ theme }) => ({
  borderRight: 'none',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: 'auto',
  maxWidth: '700px',
  margin: '5px auto',
  '& :not(:first-of-type,:last-child)': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2.0em',
  },
  '& .MuiButtonBase-root': {
    '&.MuiPaginationItem-root': {
      height: '44px',
      minHeight: '44px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '350px',
  },
}));

const MainHome = ({
  currentPage,
  listingsData,
  handlePageChange,
}) => {
  if (!listingsData || !listingsData.listings) {
    return null;
  }

  /* eslint-disable no-console */
  console.log('listingsData:', listingsData);

  return (
    <>
      <section className="body-content">
        <Hero />
        <Box sx={{ flexGrow: 1, p: 1, mt: 1 }}>
          <Grid container justifyContent="center">
            {listingsData?.listings.map((listing) => {
              return (
                <Grid item key={listing.id} style={{ margin: '5px' }}>
                  <ListingCard
                    listing={listing}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Grid container sx={{ mt: 1, pr: 1 }}>
          <Grid item xs={12} container justifyContent="flex-end">
            {/* styles from nfp-skeleton assigned to "nav" element
              are polluting this pagination control */}
            <NumberlessPagination
              siblingCount={0}
              count={listingsData.has_next ? listingsData.page + 1 : listingsData.page}
              page={currentPage}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...item}
                  sx={{ borderRadius: '4px', margin: '0 5px', fontSize: '1.5em' }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{
          maxWidth: '350px',
          margin: '20px auto',
          '@media (min-width: 1200px)': {
            display: 'none', // This hides the Box on screens larger than 1200px
          },
        }}
        >
          <TollFreeAdvert />
          <StayConnected />
        </Box>
      </section>
      <aside>
        <TollFreeAdvert />
        <StayConnected />
      </aside>
    </>
  );
};

MainHome.defaultProps = {
  listingsData: [],
  pageCount: 0,
  currentPage: 1,
};

MainHome.propTypes = {
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  listingsData: PropTypes.array,
  handlePageChange: PropTypes.func.isRequired,
};

export default MainHome;
