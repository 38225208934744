import { useContext, useEffect } from 'react';

import ModalsContext from 'app/contexts/ModalsContext';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import FavoriteAdvisorModalContainer from 'app/containers/modals/FavoriteAdvisorModalContainer';
import AllowPushNotificationsDialogContainer from 'app/containers/dialogs/AllowPushNotificationsDialogContainer';
import SecondaryAuthDialogContainer from 'app/containers/modals/SecondaryAuthDialogContainer';
import LeaveFeedbackModalContainer from 'app/containers/modals/LeaveFeedbackModalContainer';

const WindowEventsListener = () => {
  const { openModal } = useContext(ModalsContext);
  const { openDialog } = useContext(DialogsContext);

  const searchParams = new URLSearchParams(window.location.search);
  const authTo = searchParams.get('auth_to');
  const httpMethod = (searchParams.get('http_method') || 'GET').toUpperCase();
  searchParams.delete('Submit');
  searchParams.delete('http_method');
  searchParams.delete('auth_to');

  const modalList = {
    LeaveFeedbackModalContainer,
  };

  const handleOpenTributeModalEvent = (evt) => {
    // if the tribute is for the current user, don't open the modal
    if (evt.detail.advisorLogin === evt.detail.currentUserLogin) {
      return false;
    }

    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: evt.detail.advisorLogin },
    });

    return true;
  };

  const handleOpenAddToFavoritesModalEvent = (evt) => {
    openModal({
      component: FavoriteAdvisorModalContainer,
      props: { listing: evt.detail.listing },
    });
  };

  const handleOpenSecondaryAuthDialogEvent = (evt) => {
    // allow this to be called from an event listener, or
    //  manually in which case it will use a query param
    const destination = evt ? evt.detail.destination : authTo;

    const navMethod = () => {
      // for simple GET requests, just navigate to the destination
      if (httpMethod === 'GET') {
        window.location.href = destination;
        return;
      }

      // for all other HTTP Verbs, create a form and submit it,
      //  and include a _method param if necessary
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = destination;

      if (httpMethod !== 'POST') {
        const methodInput = document.createElement('input');
        methodInput.type = 'hidden';
        methodInput.name = '_method';
        methodInput.value = httpMethod;
        form.appendChild(methodInput);
      }

      searchParams.forEach((value, key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    };

    openDialog({
      component: SecondaryAuthDialogContainer,
      props: { navMethod },
    });
  };

  // NOTE:
  // You will need your element to incldue a data attribute called data-open-plus-modal to trigger this code
  // EX: data-open-plus-modal="your-modal-name"
  // See: app/helpers/account_helper.rb
  const handleOpenPlusModalEvent = (evt) => {
    const modal = modalList[evt.detail.openPlusModal];
    if (modal) {
      openModal({
        component: modal,
        props: evt.detail,
      });
    }
  };

  const handleOpenAllowPushNotificationsDialogEvent = () => {
    openDialog({ component: AllowPushNotificationsDialogContainer });
  };

  useEffect(() => {
    window.addEventListener('openTributeModal', handleOpenTributeModalEvent);
    window.addEventListener('openAddToFavoritesModal', handleOpenAddToFavoritesModalEvent);
    window.addEventListener('openSecondaryAuthDialog', handleOpenSecondaryAuthDialogEvent);
    window.addEventListener('openAllowPushNotificationsDialog', handleOpenAllowPushNotificationsDialogEvent);

    if (authTo) {
      handleOpenSecondaryAuthDialogEvent();
    }
    window.addEventListener('openPlusModal', handleOpenPlusModalEvent);


    // cleanup function
    return () => {
      window.removeEventListener('openTributeModal', handleOpenTributeModalEvent);
      window.removeEventListener('openAddToFavoritesModal', handleOpenAddToFavoritesModalEvent);
      window.removeEventListener('openSecondaryAuthDialog', handleOpenSecondaryAuthDialogEvent);
      window.removeEventListener('openPlusModal', handleOpenPlusModalEvent);
    };
  }, []);

  // we probably will never need
  // to render any UI stuff
  return null;
};

export default WindowEventsListener;
