import { useEffect } from 'react';

export const useServerErrors = (updateStatus, recordKey, setError) => {
  // TODO: consider moving mutation and form instead of passing them as arguments
  // Then again, maybe all of this can be wrapped in the formwrapper or some master form function or
  // a superclass for the container components
  useEffect(() => {
    if (updateStatus?.isError) {
      const errorData = updateStatus.error.data;
      const recordErrors = errorData?.[recordKey]?.errors;

      if (recordErrors && typeof recordErrors === 'object') {
        Object.entries(recordErrors).forEach(([field, messages]) => {
          setError(field, { type: 'server', message: messages.join(', ') });
        });
      } else {
        console.error('Error parsing server errors: ', errorData);
      }
    }
  }, [updateStatus, setError, recordKey]);
};

export default useServerErrors;
