import React from 'react';

import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

// Styles for [ Top Level, Second Level, Third Level ]
const CATEGORY_STYLES_BY_DEPTH = [
  {
    fontSize: '1em !important',
    textTransform: 'uppercase',
  },
  {
    fontSize: '1em !important',
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'outside',
  },
  {
    fontSize: '1em !important',
    display: 'block',
    width: '100%',
  },
];

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => (
  {
    padding: '5px 0',
    '& .MuiPaper-root.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
    '& .MuiPaper-root a': {
      display: 'block',
      width: '100%',
    },
    // custom styles for all of the Accordion (wrapper) components
  }
));

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))(() => (
  {
    // custom styles for all of the Expandable acccordion labels
    '& > .MuiAccordionSummary-content': {
      margin: 0,
    },
    minHeight: 0,
    padding: 0,
  }
));

const categoryToAccordion = ({ id, name, path, children, depth = 0 }, index) => {
  if (children && children.length > 0) {
    return (
      <StyledAccordion key={id} defaultExpanded={index === 0} test-data-id={`accordion-${id} ${name}`}>

        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} test-data-id={`accordion-summary-${id} ${name}`}>
          <Typography sx={CATEGORY_STYLES_BY_DEPTH[depth]}>
            <a href={`/categories/${path}`} test-data-id={`link-${id} ${name}`}>{name}</a>
          </Typography>
        </StyledAccordionSummary>

        <AccordionDetails test-data-id={`accordion-details-${id} ${name}`}>
          { children.map((cat, i) => categoryToAccordion({ ...cat, depth: depth + 1 }, i)) }
        </AccordionDetails>

      </StyledAccordion>
    );
  } else {
    return (
      <StyledAccordion key={id} test-data-id={`accordion-${id} ${name}`}>
        <StyledAccordionSummary test-data-id={`accordion-summary-${id} ${name}`}>
          <Typography sx={CATEGORY_STYLES_BY_DEPTH[depth]}>
            <a href={`/categories/${path}`} test-data-id={`link-${id} ${name}`}>
              {name}
            </a>
          </Typography>
        </StyledAccordionSummary>
      </StyledAccordion>
    );
  }
};

const CategoryListing = () => {
  const { categories } = window;

  return (
    <div className="category-listing">
      { categories.map(categoryToAccordion) }
    </div>
  );
};

export default CategoryListing;
