import React from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';

import {
  currentSystemPushPermissions,
  enablePushNotifications,
} from 'app/helpers/pushNotificationsHelpers';

const NotificationsContainer = () => {
  const currentPermissions = currentSystemPushPermissions();

  if (currentPermissions === 'granted') {
    return (
      <Typography>
        Your browser notifications are currently enabled.&nbsp;
        <a href="https://support.niteflirt.com/hc/en-us/sections/28710878443917">Learn more about notifications.</a>
      </Typography>
    )
  } else if (currentPermissions === 'denied') {
    return <InstructionsPerDevice />;
  }

  return (
    <div>
      <Typography>
        Never miss a chat! Enable browser notifications to be alerted whenever you receive a new message.
      </Typography>
      <Grid container mt={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end">
        <Grid item xs={12} sm="auto" sx={{ order: { xs: 1, sm: 2 } }}>
          <Button variant="contained" fullWidth={false} sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={enablePushNotifications}>
            Enable
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const InstructionsPerDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isIOS =  userAgent.match(/iphone|ipad/)
  const isAndroid = userAgent.match(/android/);
  const isFirefox = userAgent.match(/firefox/);
  const isChromeDesktop = userAgent.match(/chrome/) && !isAndroid;
  // the !isChromeDesktop is because the word chrome and safari appear together in Chrome but not in Safari
  const isSafariDesktop = userAgent.match(/safari/) && !isIOS && !isChromeDesktop;

  let link = '';
  if (isAndroid) { // Android
    link = 'https://support.niteflirt.com/hc/en-us/articles/28710835728269';
  } else if (isIOS) { // IOS
    link = 'https://support.niteflirt.com/hc/en-us/articles/28710784518797';
  } else if (isSafariDesktop) {
    link = 'https://support.niteflirt.com/hc/en-us/articles/28710662464653';
  } else if (isChromeDesktop) {
    link = 'https://support.niteflirt.com/hc/en-us/articles/28710697161229';
  } else if (isFirefox) {
    link = 'https://support.niteflirt.com/hc/en-us/articles/28710728083469';
  } else { // Other
    link = 'https://support.niteflirt.com/hc/en-us/sections/28710878443917';
  }

  return (
    <Typography>
      You have blocked browser notifications. To enable them, go to your browser settings and allow notifications for this site.&nbsp;
      <a href={link}>Enable Notifications.</a>
    </Typography>
  )
}

export default NotificationsContainer;
