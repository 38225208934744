import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import feedsApi from 'app/api/feedsApi';
import mainApi from 'app/api/mainApi';
import assetApi from 'app/api/assetApi';
import profileImageApi from 'app/api/profileImageApi';
import flashSlice from 'app/slices/flashSlice';
import snackbarMessageSlice from 'app/slices/snackbarMessageSlice';
import { flashMiddleware, snackbarMiddleware } from 'app/middleware/uiPopupMessage';
import { clientErrorsMiddleware } from 'app/middleware/clientErrors';
import { AvailabilityRefresher } from 'app/middleware/availabilityRefresher';
import resetRecordsSlice from 'app/slices/resetRecordsSlice';
import resetCommentsSlice from 'app/slices/resetCommentsSlice';
import subscriptionSlice from 'app/slices/subscriptionSlice';
import tributesSlice from 'app/slices/tributesSlice';
import chatSlice from 'app/slices/chatSlice';
import packageSlice from 'app/slices/packageSlice';

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: {
    [feedsApi.reducerPath]: feedsApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [profileImageApi.reducerPath]: profileImageApi.reducer,
    flashes: flashSlice.reducer,
    snackbarMessages: snackbarMessageSlice.reducer,
    resetRecords: resetRecordsSlice.reducer,
    resetComments: resetCommentsSlice.reducer,
    subscription: subscriptionSlice.reducer,
    tributes: tributesSlice.reducer,
    chat: chatSlice.reducer,
    packageOnboarding: packageSlice.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      feedsApi.middleware,
      mainApi.middleware,
      assetApi.middleware,
      profileImageApi.middleware,
      flashMiddleware,
      snackbarMiddleware,
      clientErrorsMiddleware,
      AvailabilityRefresher,
    ])
  ),
});
