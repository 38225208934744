import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const CallFreeBox = styled('div')({
  margin: '10px',
  background: 'none',
  padding: '15px',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  textAlign: 'center',
  maxWidth: '326px',
});

const CallTollFree = styled('div')({
  background: '#63177a',
  color: 'white',
  fontSize: '13px',
  padding: '8px 10px 6px 10px',
  borderRadius: '2px',
  margin: '10px 13px',
  lineHeight: '1.5',
});

const TollFreeAdvert = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <CallFreeBox>
          <Box sx={{ background: 'none' }} id="awaybody">
            <h3 style={{ fontSize: '18px', color: '#555' }}>
              Get the best phone sex anywhere, anytime!
            </h3>
            <CallTollFree>
              Call NiteFlirt toll-free
              <div className="number">
                <a
                  style={{ color: '#fff', fontSize: '16px', fontWeight: '300' }}
                  href="tel:1-407-965-4150"
                >
                  {/* this used to be dynamic in erb, we may still need to do that */}
                  1-407-965-4150
                </a>
              </div>
            </CallTollFree>
            <div className="number-small">
              (
              <a
                href="tel:1-407-965-4150"
                style={{ color: 'rgb(102, 102, 102)', fontSize: '13px' }}
              >
                {/* this used to be dynamic in erb, we may still need to do that */}
                1-800-TO-FLIRT
              </a>
              )
            </div>
          </Box>
        </CallFreeBox>
      </Grid>
    </Grid>
  );
};

export default TollFreeAdvert;
