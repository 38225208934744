import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import SearchContext from 'app/contexts/SearchContext';

const SearchContainer = ({ children }) => {
  const [toggleRef, setToggleRef] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    toggleRef,
    setToggleRef,
    searchString,
    setSearchString,
    searchHasFocus,
    setSearchHasFocus,
    searchVisible,
    setSearchVisible,
  };

  return (
    <SearchContext.Provider value={context}>
      {children}
    </SearchContext.Provider>
  );
};

SearchContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

export default SearchContainer;
