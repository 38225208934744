// this transforms the post item into the format expected by the lightbox
export const postItemToMediaParams = (postItem) => {
  switch (postItem.item_type) {
  case 'video': return ({
    id: postItem.fm_item_id,
    src: postItem.large_url,
    thumb: postItem.thumbnail_url,
    poster: postItem.thumbnail_url,
    contentType: postItem.content_type,
    previewsPreviewable: true, // Post items are always previewable, they are filterd in the backend.
    type: postItem.item_type,
    fm_item_id: postItem.fm_item_id,
  });
  case 'image': return ({
    id: postItem.fm_item_id,
    src: postItem.large_url,
    thumb: postItem.thumbnail_url,
    contentType: postItem.content_type,
    type: postItem.item_type,
    fm_item_id: postItem.fm_item_id,
  });
  case 'audio': return ({
    id: postItem.fm_item_id,
    src: postItem.large_url,
    thumb: postItem.thumbnail_url,
    preview: postItem.thumbnail_url,
    type: postItem.item_type,
    fm_item_id: postItem.fm_item_id,
  });
  default:
    /* eslint-disable no-console */
    console.warn(`unrecognized media type ${postItem.item_type}`);
    return null;
  }
};

export const goodyBagItemToMediaParams = (goodyBagItem) => {
  switch (goodyBagItem.item_type) {
  case 'video': return ({
    id: goodyBagItem.id,
    src: goodyBagItem.large_url,
    thumb: goodyBagItem.thumbnail_url,
    poster: goodyBagItem.thumbnail_url,
    previewUrls: goodyBagItem.preview_urls,
    contentType: goodyBagItem.content_type,
    previewsPreviewable: goodyBagItem.previews_previewable,
    type: goodyBagItem.item_type,
  });
  case 'image': return ({
    id: goodyBagItem.id,
    src: goodyBagItem.large_url,
    thumb: goodyBagItem.thumbnail_url,
    contentType: goodyBagItem.content_type,
    type: goodyBagItem.item_type,
  });
  case 'audio': return ({
    id: goodyBagItem.id,
    src: goodyBagItem.large_url,
    thumb: goodyBagItem.thumbnail_url,
    preview: goodyBagItem.thumbnail_url,
    type: goodyBagItem.item_type,
  });
  default: return ({
    id: goodyBagItem.id,
    type: goodyBagItem.item_type,
  });
  }
};
