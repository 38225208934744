import React, { useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';

import SearchContext from 'app/contexts/SearchContext';
import SearchControls from 'app/components/SearchControls';
import { useSearchAdvisorsQuery } from 'app/api/mainApi';

const SearchControlsContainer = () => {
  const searchInpRef = useRef();
  const searchContainerRef = useRef();
  const navigate = useNavigate();

  const {
    toggleRef,
    searchVisible,
    setSearchVisible,
    searchString,
    setSearchString,
    searchHasFocus,
    setSearchHasFocus,
  } = useContext(SearchContext);

  const { data: flirts } = useSearchAdvisorsQuery({ searchString }, { skip: !searchHasFocus });

  const onSearchToggleClick = () => {
    setSearchVisible(!searchVisible);
  };

  useEffect(() => {
    if (toggleRef?.current) {
      toggleRef.current.addEventListener('click', onSearchToggleClick, { once: true });
    }
  }, [searchVisible]);

  const clearSearchString = () => {
    searchInpRef.current.value = '';
    setSearchString('');
  };

  const updateSearchString = (e) => {
    setSearchString(e.currentTarget.value);
  };

  const onSearchInputFocus = () => {
    setSearchHasFocus(true);
  };

  const onWindowClick = (e) => {
    // these refs may not be set yet
    if (!searchContainerRef.current || !toggleRef.current) return;

    // we only care about clicks outside of the search area
    if (!searchContainerRef.current.contains(e.target) && !toggleRef.current.contains(e.target)) {
      clearSearchString();
      setSearchHasFocus(false);
      setSearchVisible(false);
    }
  };

  const removeClickHandlers = () => {
    window.removeEventListener('click', onWindowClick);

    if (toggleRef?.current) {
      toggleRef.current.removeEventListener('click', onSearchToggleClick);
    }
  };

  const navigateToFlirt = (flirtUrl) => {
    removeClickHandlers();
    navigate(flirtUrl);
  };

  useEffect(() => {
    // moved adding event handler for window click to
    // useEffect [searchContainerRef, toggleRef]
    // window.addEventListener('click', onWindowClick);

    return () => {
      setSearchVisible(false);
      setSearchHasFocus(false);
      removeClickHandlers();
    };
  }, []);

  useEffect(() => {
    if (toggleRef?.current) {
      toggleRef.current.addEventListener('click', onSearchToggleClick, { once: true });
    }

    if (toggleRef?.current && searchContainerRef?.current) {
      window.addEventListener('click', onWindowClick);
    }
  }, [searchContainerRef, toggleRef]);

  const getFlirts = () => {
    return searchHasFocus ? flirts : null;
  };

  return (
    <SearchControls
      flirts={getFlirts()}
      visible={searchVisible}
      searchInpRef={searchInpRef}
      searchContainerRef={searchContainerRef}
      clearSearchField={clearSearchString}
      onSearchInputFocus={onSearchInputFocus}
      updateSearchField={updateSearchString}
      navigateToFlirt={navigateToFlirt}
    />
  );
};

export default SearchControlsContainer;
