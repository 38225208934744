import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import {
  useGetListingQuery,
  useGetPhonesQuery,
  useGetAvailableBalanceQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import {
  Box,
  Divider,
} from '@mui/material';

import AppBody from 'app/components/layout/AppBody';
import Listing from 'app/components/call/Listing';
import CallInfo from 'app/components/call/CallInfo';
import SelectPhoneNumber from 'app/components/call/SelectPhoneNumber';
import CurrentBalance from 'app/components/call/CurrentBalance';
import TalkTime from 'app/components/call/TalkTime';
import CallNow from 'app/components/call/CallNow';
import HowItWorksToggle from 'app/components/call/HowItWorksToggle';

import CannotCallSelfError from 'app/components/call/errors/CannotCallSelfError';
import ViewerBlockedError from 'app/components/call/errors/ViewerBlockedError';
import NeedsPhoneError from 'app/components/call/errors/NeedsPhoneError';

const CallContainer = () => {
  const { data: appConfig, isLoading: isAppConfigLoading } = useGetAppConfigQuery();
  const { current_user: currentUser } = appConfig || {};
  const { id: listingId } = useParams();
  const { data: listing, isLoading: isListingLoading } = useGetListingQuery({ id: listingId });
  const { data: phones, isLoading: isPhonesLoading } = useGetPhonesQuery();
  const [selectedPhone, setSelectedPhone] = useState(null);
  const { data: balance, isLoading: isBalanceLoading } = useGetAvailableBalanceQuery();
  const isListingOwner = listing?.advisor_id === currentUser?.id;
  const isViewerBlocked = listing?.blocked;

  useEffect(() => {
    if (phones) {
      const defaultPhone = phones.find((phone) => phone.default);
      setSelectedPhone(defaultPhone || phones[0]);
    }
  }, [phones]);

  return (
    <AppBody>
      <Box sx={{
        marginTop: '8px',
        paddingTop: '40px',
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        mb: 1,
      }}
      >
        <Listing
          listing={listing}
          isLoading={isListingLoading}
        />
      </Box>
      <Divider
        sx={{
          mt: 3,
          mx: '32px',
        }}
      />
      <CallInfo />
      <SelectPhoneNumber
        phones={phones}
        setSelectedPhone={setSelectedPhone}
        isLoading={isPhonesLoading}
      />
      <CurrentBalance
        balance={balance}
        listing={listing}
        isBalanceLoading={isBalanceLoading}
        isListingLoading={isListingLoading}
      />
      <TalkTime
        listing={listing}
        balance={balance}
        isLoading={isBalanceLoading || isListingLoading}
      />
      <HowItWorksToggle />
      <ViewerBlockedError
        listing={listing}
        isLoading={isListingLoading}
      />
      <CannotCallSelfError
        listing={listing}
        viewer={currentUser}
        isLoading={isAppConfigLoading || isListingLoading}
      />
      { !(isViewerBlocked || isListingOwner) && (
        <>
          <CallNow
            selectedPhone={selectedPhone}
            listing={listing}
            balance={balance}
            isLoading={isBalanceLoading || isListingLoading || isPhonesLoading}
          />
          <NeedsPhoneError
            phones={phones}
            isLoading={isPhonesLoading}
          />
        </>
      )}
    </AppBody>
  );
};

export default CallContainer;
