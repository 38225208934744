import React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Link,
} from '@mui/material';

const TransientMobileNav = () => {
  const { hasUnreadMessages } = useSelector(state => state.chat);
  return (
    <Box
      test-data-id="transient-mobile-nav"
      sx={{
        position: 'fixed',
        bottom: 0,
        height: '40px',
        width: '100%',
        display: {
          xs: 'block',
          md: 'none',
        },
      }}
    >
      <Box
        sx={{
          background: 'black',
          fontSize: '12px',
          height: '100%',
          width: '100%',
        }}
        display="flex"
        alignItems="stretch"
        justifyContent="space-evenly"
      >
        <Link
          title="Phone Sex"
          href="/search"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            textShadow: '0 1px 1px rgba(0,0,0,.7)',
            lineHeight: '3.4',
            fontFamily: 'Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
          }}
        >
          PHONE SEX
        </Link>
        <Box sx={{
          borderRight: '1px solid #000',
        }}
        >
          &nbsp;
        </Box>
        
        <Box sx={{ position: 'relative' }}>
          <Link
            title="Chat"
            href="/chat"
            sx={{
              color: 'white !important',
              textDecoration: 'none',
              textShadow: '0 1px 1px rgba(0,0,0,.7)',
              lineHeight: '3.4',
              fontFamily: 'Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
            }}
          >
            CHAT
          </Link>
          {hasUnreadMessages && (
            <span style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              backgroundColor: 'red',
              borderRadius: '50%',
            }}>
              &nbsp;
            </span>
          )}
        </Box>
        <Box sx={{
          borderRight: '1px solid #000',
        }}
        >
          &nbsp;
        </Box>

        <Link
          title="Goodies"
          href="/goody/search"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            textShadow: '0 1px 1px rgba(0,0,0,.7)',
            lineHeight: '3.4',
            fontFamily: 'Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
          }}
        >
          GOODIES
        </Link>
        <Box sx={{
          borderRight: '1px solid #000',
        }}
        >
          &nbsp;
        </Box>

        <Link
          title="Feeds"
          href="/feed"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            textShadow: '0 1px 1px rgba(0,0,0,.7)',
            lineHeight: '3.4',
            fontFamily: 'Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
          }}
        >
          FEEDS
        </Link>
         <Box sx={{
          borderRight: '1px solid #000',
        }}
        >
          &nbsp;
        </Box>

         <Link
          title="Account"
          href="/account"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            textShadow: '0 1px 1px rgba(0,0,0,.7)',
            lineHeight: '3.4',
            fontFamily: 'Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
          }}
        >
          ACCOUNT
        </Link>
      </Box>
    </Box>
  );
};

export default TransientMobileNav;
