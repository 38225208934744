import { useContext } from 'react';
import SignOutModal from 'app/components/modals/SignOutModal';
import ModalsContext from 'app/contexts/ModalsContext';
import { featureFlag } from 'app/util/featureFlag';

const useSignOut = () => {
  const { openModal } = useContext(ModalsContext);
  const enabled = featureFlag.enabled('sign_in_20142');

  const signOut = (type) => {
    if (enabled) {
      openModal({
        component: SignOutModal,
        props: { type },
        noDefaultBody: true
      });
      return;
    }
    window.location.href = '/logout';
  };

  return { signOut };
};

export default useSignOut;