import React from 'react';
import { Button } from '@mui/material';
import { featureFlag } from 'app/util/featureFlag';
import { hasServiceWorker } from 'app/helpers/pwaHelpers';

const UpdateServiceWorkerButton = () => {
  const canRender = featureFlag.enabled('pwa_dev_tools') && hasServiceWorker;

  const sendUpdateMessage = () => {
    console.log('Sending message to service worker to update app...');

    navigator.serviceWorker.controller.postMessage({
      command: 'UPDATE_APP',
      message: `Trigger App Update at ${new Date().toISOString()}`,
    });
  };

  const updateServiceWorker = () => {
    navigator.serviceWorker.ready.then((registration) => {
      const controllingServiceWorker = navigator.serviceWorker.controller;

      console.log('Currently Registered Service Worker:', registration.active);
      if (!controllingServiceWorker) {
        console.log('There is no service worker controlling page.  Updating the currently registered service worker.');
        registration.update().then((registration) => {
          console.log('Registered ServiceWorker State:', registration.active.state);
        });
      } else {
        console.log('Updating the active service worker controlling page:', controllingServiceWorker);
        sendUpdateMessage();
      }
    });
  };

  const markup = canRender && (
    <Button
      onClick={updateServiceWorker}
      variant="contained"
      color="primary"
      style={{ zIndex: 1, fontSize: '10px', position: 'fixed', top: '30px', left: '30px' }}
    >
      Update Service Worker
    </Button>
  );
  return (markup);
};

export default UpdateServiceWorkerButton;
