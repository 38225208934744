import React from 'react';

import HowItWorks from 'app/components/call/HowItWorks';

const HowItWorksAsideContainer = () => {
  return (
    <aside>
      <HowItWorks />
    </aside>
  );
};

export default HowItWorksAsideContainer;
