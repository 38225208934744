
export const isNotificationApiSupported = () => {
  return 'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window;
}

export const currentSystemPushPermissions = () => {
  if (!isNotificationApiSupported()) return 'denied';
  return Notification.permission;
}

export const enablePushNotifications = async () => {
  const status = await Notification.requestPermission();
  localStorage.setItem('pn-remind-later', 'never');

  window.location.reload();
};