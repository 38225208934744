import React from 'react';

import { PropTypes } from 'prop-types';

import { DateTime } from 'luxon';

const ScheduleBanner = ({
  scheduledAt,
  timezone,
}) => {
  return (
    <div className="body-alert general">
      <div className="container-fluid padding-0-8">
        <div className="row middle-xs">
          <div className="col-xs-11">
            <div className="row middle-xs">
              <div className="paddinglft5 paddingrgt10">
                <img src="/plus_frontend/assets/svg/ico-posts-queue.svg" alt="Posts Queue" />
              </div>
              <div test-data-id="schedule-message">
                Scheduled for&nbsp;
                { DateTime.fromISO(scheduledAt).setZone(timezone).toLocaleString(DateTime.DATETIME_MED) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduleBanner.propTypes = {
  scheduledAt: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ScheduleBanner;
