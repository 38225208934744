import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import PubNub from 'pubnub';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { decodeHtml } from 'app/helpers/htmlHelpers';

import {
  BALANCE_CHANGE,
  PROFILE_IMAGE_CHANGE,
  HAS_UNREAD_CHAT_MESSAGES,
  CALL_COMING,
  CALL_FAILED,
  NEW_CHAT_MESSAGE,
} from 'app/constants/pubnubActionTypes';

import {
  APP_CONFIG_TAG,
  ADVISOR_TAG,
  AVAILABLE_BALANCE_TAG,
} from 'app/api/sharedApiTags';

import {
  useGetAppConfigQuery,
  useGetPubNubConfigQuery,
  ADVISOR_LISTINGS_TAG,
  AVAILABILITY_TAG,
} from 'app/api/mainApi';

import { setHasUnreadMessages } from 'app/slices/chatSlice';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

// dummy pubnub client satisfies
// the PubNubProvider "client" prop
const makePubNubMock = () => ({
  subscribe: () => {},
  addListener: () => {},
  removeListener: () => {},
  unsubscribeAll: () => {},
});

// this component is responsible for listening for pubnub messages
// translating them into redux/slice actions, and dispatching them
const PubNubActionDispatcher = ({ pubNubConfig, currentUser }) => {
  if (!pubNubConfig || !currentUser) return null;

  const dispatch = useDispatch();
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  const {
    pubnub_private_updates_channel: pubnubPrivateUpdatesChannel,
    pubnub_private_chat_channel: pubnubPrivateChatChannel,
    pubnub_push_notification_channel: pubnubPushNotificationChannel,
  } = pubNubConfig;

  const handleCallNotifier = (msg) => {
    if (!msg.message.match || !msg.message.match(/^(call|availability)_\w+/)) return false;

    const [callType, content, login] = msg.message.split('<==>', 3);

    switch (callType) {
    case (CALL_COMING):
      toast.info(<SanitizedHTML html={content} options={{allowedTags: false, allowedAttributes: false}} />, { toastId: 'call_notifier_toast' });
      document.getElementById('callAlertSound').play();
      break;
    case (CALL_FAILED):
      toast.info(<SanitizedHTML html={content} options={{allowedTags: false, allowedAttributes: false}} />, { toastId: 'call_notifier_toast' });
      document.getElementById('callFailSound').play();
      break;
    case 'availability_change':
      dispatchInvalidateCacheTagsEvent([
        { type: AVAILABILITY_TAG },
        { type: ADVISOR_LISTINGS_TAG, id: login.toLowerCase() },
      ]);
      break;
    case 'availability_change_chat':
      if (login) {
        dispatchInvalidateCacheTagsEvent([{ type: ADVISOR_LISTINGS_TAG, id: login.toLowerCase() }]);
      }
      break;
    default: return false;
    }

    return true;
  };

  const handlePubNubMessage = (msg) => {
    const { message } = msg;

    switch (message.action) {
    case BALANCE_CHANGE:
      dispatchInvalidateCacheTagsEvent([APP_CONFIG_TAG, AVAILABLE_BALANCE_TAG]);
      break;
    case PROFILE_IMAGE_CHANGE:
      dispatchInvalidateCacheTagsEvent([ADVISOR_TAG]);
      break;
    case HAS_UNREAD_CHAT_MESSAGES:
      dispatch(setHasUnreadMessages({ hasUnreadMessages: message.payload.has_unread_chat_messages }));
      break;
    case NEW_CHAT_MESSAGE:
      let messageContent;
      switch (message.payload.message.message_class) {
      case 'photo_message':
        messageContent = (
          <>
            { decodeHtml(message.payload.contact.other_user.name) }
            &nbsp;sent you a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>photo!</a>
          </>
        );
        break;
      case 'gift_message':
        messageContent = (
          <>
            { decodeHtml(message.payload.contact.other_user.name) }
            &nbsp;sent you a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>tribute!</a>
          </>
        );
        break;
      case 'gift_request_message':
        messageContent = (
          <>
            { decodeHtml(message.payload.contact.other_user.name) }
            &nbsp;requested a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>tribute!</a>
          </>
        );
        break;
      default:
        messageContent = (
          <>
            You have a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>new chat message</a>
            &nbsp;from&nbsp;
            { decodeHtml(message.payload.contact.other_user.name) }
            :
            <br />
            { decodeHtml(message.payload.message.content_to_show_in_alert) }
          </>
        );
      }

      toast.info(messageContent);
      break;
    default:
      // if we don't handle the message, we'll log that it was unhandled
      if (!handleCallNotifier(msg)) {
        console.warn('unhandled pubnub message in FeedsPubNubContainer', msg);
      }
    }
  };

  const pubNub = usePubNub();

  useEffect(() => {
    if (!currentUser) return false;

    const channels = [
      pubnubPrivateUpdatesChannel,
      pubnubPrivateChatChannel,
      pubnubPushNotificationChannel,
    ];

    console.log('adding pubnub listener in FeedsPubNubContainer (PubNubActionDispatcher)');
    console.log('subscribing to channels', channels);

    pubNub.addListener({ message: handlePubNubMessage });
    pubNub.subscribe({ channels });

    return () => {
      if (!currentUser) return;

      pubNub.removeListener(handlePubNubMessage);
      pubNub.unsubscribeAll();
    };
  }, []);

  return null;
};

const FeedsPubNubContainer = ({ children }) => {
  let pubNubClient;
  const pubNubConfig = useGetPubNubConfigQuery().data;
  const appConfig = useGetAppConfigQuery().data;

  if (pubNubConfig && appConfig?.current_user) {
    pubNubClient = new PubNub({
      subscribeKey: pubNubConfig.pubnub_subscribe_key,
      userId: appConfig.current_user.id.toString(),
    });
  } else {
    pubNubClient = makePubNubMock();
  }

  return (
    <PubNubProvider client={pubNubClient}>
      <PubNubActionDispatcher pubNubConfig={pubNubConfig} currentUser={appConfig?.current_user} />
      {children}
    </PubNubProvider>
  );
};

FeedsPubNubContainer.defaultProps = {
  children: null,
};

FeedsPubNubContainer.propTypes = {
  children: PropTypes.array,
};

export default FeedsPubNubContainer;
