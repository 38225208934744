import React from 'react';
import { PropTypes } from 'prop-types';
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
  },
  allowedIframeHostnames: [],
};

const sanitize = (dirtyHtml, options) => ({
  __html: sanitizeHtml(
    dirtyHtml,
    { ...defaultOptions, ...options },
  ),
});

const SanitizedHTML = ({ html, options }) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
);

SanitizedHTML.defaultProps = {
  html: '',
  options: {},
};

SanitizedHTML.propTypes = {
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.object,
};

export default SanitizedHTML;
