import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useParams } from 'react-router';

import { featureFlag } from 'app/util/featureFlag';

import { useGetAvailableBalanceQuery } from 'app/api/mainApi';
import { useGetGoodyBagsForAdvisorQuery } from 'app/api/assetApi';
import { toNumber } from 'app/helpers/currencyHelpers';
import GoodyBagCardContainer from 'app/containers/GoodyBagCardContainer';

const GoodyBagsContainer = ({
  advisor,
  advisorLoading,
  viewingSelf,
  currentUser,
}) => {
  if (advisorLoading) return (<div>Loading...</div>);

  const balanceData = currentUser ? useGetAvailableBalanceQuery() : {};
  const rawAvailableBalance = balanceData?.data?.available_balance;
  const availableBalance = toNumber(rawAvailableBalance);
  const { goodyBagId } = useParams();
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  const { data: goodyBags, isLoading: goodyBagsLoading } = useGetGoodyBagsForAdvisorQuery({
    userId: advisor?.id,
    selectedGoodyBag: goodyBagId,
  });
  if (goodyBagsLoading) return (<div>Loading...</div>);
  if (!goodyBags?.length) return (<div>No Goodies Found</div>);

  // Split goody bags into rows of 2, because our layout system can't do that automatically
  const rows = Array.from(
    { length: Math.ceil(goodyBags.length / 2) },
    (_, i) => goodyBags.slice(i * 2, i * 2 + 2),
  );

  if (newCardsEnabled) {
    return (
      <Box px={{ xs: 0, sm: 2 }} pt={{ xs: 2.5, sm: 3.5 }} pb={{ xs: 10, sm: 10 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: '3px', sm: '3px' }, justifyContent: 'center' }}>
          {goodyBags.map(goodyBag => (
            <Box key={`goodyBag_${goodyBag.id}`} sx={{ width: { xs: '330px', sm: '330px' }, flex: { xs: '0 0 330px', sm: '0 0 330px' } }}>
              <GoodyBagCardContainer
                goodyBag={goodyBag}
                selected={goodyBag.id === goodyBagId}
                viewingSelf={viewingSelf}
                availableBalance={availableBalance}
                currentUser={currentUser}
                advisorLoading={advisorLoading}
                advisor={advisor}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <div className="container-fluid padding-0-8" test-data-id="goody-bags-container" style={{ maxWidth: '757px', marginLeft: '0px' }}>
      {rows.map((row) => (
        <div key={`row_${row[0].id}`} className="row">
          {row.map((goodyBag) => (
            <div key={`goodyBag_${goodyBag.id}`} className="col-sm-6 col-xs-12">
              <GoodyBagCardContainer
                goodyBag={goodyBag}
                selected={goodyBag.id === goodyBagId}
                viewingSelf={viewingSelf}
                availableBalance={availableBalance}
                currentUser={currentUser}
                advisorLoading={advisorLoading}
                advisor={advisor}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

GoodyBagsContainer.defaultProps = {
  advisor: {},
  currentUser: {},
};

GoodyBagsContainer.propTypes = {
  advisor: PropTypes.object,
  advisorLoading: PropTypes.bool.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default GoodyBagsContainer;
