import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Grid, Typography, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { callButton } from 'app/helpers/listingHelpers';

// Define styled components
const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '0px',
  borderRadius: '0px',
  backgroundColor: '#222',
  color: 'white',
});

const BlurBox = styled(Box)({
  zIndex: '-1',
  filter: 'blur(10px) opacity(80%)',
  transform: 'translate3d(0, 0, 0)',
  overflow: 'hidden',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '200%',
  width: '150px',
  height: '180px',
  clipPath: 'inset(0)',
});

const RightColumn = styled(Grid)({
  padding: '10px 15px 0px 15px',
  width: '200px',
});

const NameTypography = styled(Typography)({
  fontWeight: '300',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  position: 'relative',
});

const DescBox = styled(Box)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  height: '3em',
  marginTop: '5px',
  fontSize: '0.813em',
  lineHeight: '1.5',
  '& a': {
    color: 'white !important',
  },
  '& a:hover': {
    textDecoration: 'underline',
  },
});

const RatingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  marginTop: '5px',
});

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

const CustomRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ffb400', // Yellow color for filled stars
  },
  '& .MuiRating-iconEmpty': {
    color: '#999', // Gray outline for empty stars
  },
  '& .MuiRating-icon': {
    fontSize: '1em', // Adjust the font size of the stars if needed
  },
});

const ListingCard = ({ listing }) => {
  const truncatedName = truncateText(listing.login, 20);
  const truncatedDescription = truncateText(listing.description, 50);

  return (
    <StyledCard>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Box
            sx={{
              width: '150px',
              height: '180px',
              position: 'absolute',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1',
            }}
          >
            <img src={listing.photo_url} alt="Card" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </Box>
          <BlurBox style={{ backgroundImage: `url(${listing.photo_url})` }} />
        </Grid>
        <RightColumn item xs={6}>
          <NameTypography variant="h6">{truncatedName}</NameTypography>
          <DescBox className="description">
            <a href="#">{truncatedDescription}</a>
          </DescBox>
          <RatingContainer>
            <CustomRating name="rating" value={listing.rating} precision={0.5} readOnly size="small" />
            <Typography sx={{ margin: '-1px 0 0 10px', color: '#aaa', fontSize: '0.813em' }}>
              {`(${listing.rating})`}
            </Typography>
          </RatingContainer>
          <Grid container alignItems="center" style={{ padding: '0px', marginTop: '10px' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              {callButton(listing)}
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '7px' }}>
              <Typography variant="body2" sx={{ color: '#999', fontSize: '0.813em' }}>
                <span style={{ color: '#fff', display: 'inline-block' }}>
                  {`$${listing.price_per_minute}`}
                </span>
                <span style={{ display: 'inline-block' }}>&nbsp;per min.</span>
              </Typography>
            </Grid>
          </Grid>
        </RightColumn>
      </Grid>
    </StyledCard>
  );
};

ListingCard.propTypes = {
  listing: PropTypes.shape({
    photo_url: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    login: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price_per_minute: PropTypes.string.isRequired,
    buttonClass: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ListingCard;
