import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { toCurrency } from 'app/helpers/currencyHelpers';
import { usePurchaseGoodyBagMutation } from 'app/api/assetApi';
import BuyGoodyBagSuccessModal from './BuyGoodyBagSuccessModal';
import AskToAddMoneyModal from './AskToAddMoneyModal';
import { GOODY_BAG_BLOCKED_ERROR, GOODY_BAG_FUNDS_ERROR } from 'app/middleware/clientErrors';
import GoodyBagPurchaseBlocked from './GoodyBagPurchaseBlocked';

const BuyGoodyBagModal = ({
  goodyBag,
  currentUser,
  openModal,
  closeModal,
  availableBalance,
  onAddToBalanceSuccess,
}) => {
  const [purchaseGoodyBag, purchaseGoodyBagResult] = usePurchaseGoodyBagMutation();
  const onPurchaseGoodyBagClick = () => {
    if (!purchaseGoodyBagResult?.isLoading) {
      purchaseGoodyBag({ goodyBagId: goodyBag.id });
    }
  };

  const handlePurchaseGoodyBagSuccess = () => {
    closeModal();
    openModal({
      component: BuyGoodyBagSuccessModal,
      props: {
        closeModal,
      },
    });
  };

  const handlePurchaseGoodyBagError = (errorCode) => {
    closeModal();
    if (errorCode === GOODY_BAG_FUNDS_ERROR) {
      openModal({
        component: AskToAddMoneyModal,
        props: {
          openModal,
          closeModal,
          goodyBag,
          currentUser,
          availableBalance,
          onAddToBalanceSuccess,
        },
      });
    }

    if (errorCode === GOODY_BAG_BLOCKED_ERROR) {
      openModal({
        component: GoodyBagPurchaseBlocked,
        props: { closeModal }
      });
    }
  };

  useEffect(() => {
    if (purchaseGoodyBagResult?.isSuccess) {
      handlePurchaseGoodyBagSuccess();
    }

    if (purchaseGoodyBagResult?.error?.status === 403) {
      handlePurchaseGoodyBagError(purchaseGoodyBagResult?.error?.data?.code);
    }
  }, [purchaseGoodyBagResult]);

  return (
    <div>
      <div className="modal-header">
        <h4>Purchase Confirmation</h4>
      </div>

      <div className="modal-body">
        <div>
          {toCurrency(goodyBag.price)}
          &nbsp;will be deducted from your account.  Purchased items will be added to your File Player where they can be viewed or downloaded.
        </div>
      </div>
      <div className="modal-footer">
        <a href="#" className="btn secondary btn-cancel" onClick={closeModal}>Cancel</a>
        <button
          type="button"
          className="btn"
          onClick={onPurchaseGoodyBagClick}
          disabled={purchaseGoodyBagResult?.isLoading}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

BuyGoodyBagModal.defaultProps = {
  currentUser: {},
};

BuyGoodyBagModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  goodyBag: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  availableBalance: PropTypes.number.isRequired,
  onAddToBalanceSuccess: PropTypes.func.isRequired,
};

export default BuyGoodyBagModal;
