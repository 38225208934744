import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

const BuyGoodyBagSuccessModal = ({ closeModal }) => {
  return (
    <div>
      <div className="modal-header">
        <h4>Your purchase was successful!</h4>
      </div>

      <div className="modal-body">
        <div>
          What would you like to do next?
        </div>
      </div>
      <div className="modal-footer">
        <Link
          to="/account/fileplayer"
          className="btn secondary"
          reloadDocument
        >
          View files in File Player
        </Link>
        <button
          type="button"
          className="btn"
          onClick={closeModal}
        >
          Continue Shopping
        </button>
      </div>
    </div>
  );
};

BuyGoodyBagSuccessModal.defaultProps = {
};

BuyGoodyBagSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default BuyGoodyBagSuccessModal;
