import React from 'react';
import { PropTypes } from 'prop-types';

import List from '@mui/material/List';

import PerkListItem from 'app/components/tributes/PerkListItem';

import { featureFlag } from 'app/util/featureFlag';

const PerkList = ({ packageData }) => {
  const { feedPerk, callPerk, volleyPerk } = packageData;

  const isChatPerkEnabled = featureFlag.enabled('CHATPERK_FREE_VOLLEYS_19676');
  const showVolleyPerk = isChatPerkEnabled && volleyPerk;

  return (
    <List test-data-id="perk-list" dense style={{ margin: '0px 10px' }}>
      {feedPerk && PerkListItem({ perkDescription: feedPerk?.perkDescription, perkType: 'feed' })}
      {callPerk && PerkListItem({ perkDescription: callPerk?.perkDescription, perkType: 'call' })}
      {showVolleyPerk && PerkListItem({ perkDescription: volleyPerk?.perkDescription, perkType: 'volley' })}
    </List>
  );
};

PerkList.propTypes = {
  packageData: PropTypes.object.isRequired,
};

export default PerkList;
